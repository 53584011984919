import React, { Component } from "react";

export class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { items } = this.props;
    return <div className="breadcrumb">
      <div className="container">
        <ul className="breadcrumb p-0 mb-0">
          {items.map(item => <li className={"breadcrumb-item " + item.className} key={item.title}>
            <a href="#">{item.title}</a>
          </li>)}
        </ul>
      </div>
    </div>
  }
}