import users_studies_model from "../models/users_studies";
const initialUserState = {
    isLogged: false,
    loading_login: false,
    response_login: false,
    loading_save: false,
    list: [],
    data: false,
    tasks: [],
    balance: [],
    payments: [],
    edit_user: {
        "name": "",
        "lastname": "",
        "email": "",
        "phone": "",
        "group_id": "",
        "password": "",
        "status": 1,
        "studies": []
    },
    groups: [],
    user_group_id: []
}

export function user(state = initialUserState, action) {
    let list = state.list;
    switch (action.type) {
        case "LOADING_LOGIN":
            return Object.assign({}, state, { loading_login: true });
        case "LOGIN_SUCCESS":
            return Object.assign({}, state, { loading_login: false, isLogged: true, data: action.response });
        case "LOGIN_ERROR":
            return Object.assign({}, state, { response_login: action.response, loading_login: false });
        case "USER_DATA":
            return Object.assign({}, state, { loading_login: false, isLogged: true, data: action.response });
        case "SAVE_COMPLETE":
            return Object.assign({}, state, { loading_login: false, response_save: action.response });
        case "CHANGE_USER_DATA":
            return Object.assign({}, state, { edit_user: action.edit_user });
        case "LOADING_LEMBRETE":
            return Object.assign({}, state, { lembrete_loading: true, lembrete_response: false });
        case "RESPONSE_LEMBRETE":
            return Object.assign({}, state, { lembrete_loading: false, lembrete_response: action.response });
        case "RESPONSE_USERS":
            return Object.assign({}, state, { list_loading: false, list: action.response });
        case "RESPONSE_DELETE_USER":
            list.splice(action.index, 1);
            return Object.assign({}, state, { list: list });
        case "RESPONSE_USER_TYPES":
            return Object.assign({}, state, { list_loading: false, groups: action.response });
        case "GET_USER_EDIT":
            return Object.assign({}, state, { edit_user: action.response });
        case "LOADING_SAVE_USER":
            return Object.assign({}, state, { loading_save: action.loading_save });
        case "USER_TASKS":
            return Object.assign({}, state, { tasks: action.response });
        case "GET_ACCOUNT_BALANCE":
            return Object.assign({}, state, { balance: action.response });
        case "GET_PAYMENTS":
            return Object.assign({}, state, { payments: action.response });
        case "GET_GROUP_USERS_ID":
            return Object.assign({}, state, { user_group_id: action.response });
        default:
            return state
    }
}