import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { getStudies, getStudiesAvailable } from "../../actions/studiesActions";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import './index.css'
import { Breadcrumbs } from "../../components/Breadcrumb";
export class Painel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  async componentDidMount() {

    let studies = await getStudiesAvailable();
    this.setState({ isLoading: false, studies: studies.data });
  }

  studyLink(item) {
    /*
    return (item.info
      ? "/studies/info/" + item.ID
      : "/studies/edit/" + item.ID);
      */
    return "/studies/info/" + item.ID;
  }

  render() {
    const { isLoading, studies } = this.state;
    return (
      <div>
        <Header />

        <Breadcrumbs items={[{ "className": 'active', 'title': 'Dashboard' }]} />

        <div className="container">
          <div className="mainHeader">
            <h3>
              <strong>Últimos estudos</strong>
            </h3>
            <Link to="/studies/create" className="btn btn-success btn-round">
              <i className="fa fa-plus"></i>&nbsp;Novo Estudo
            </Link>
          </div>
          {isLoading ? (

            <p className="text-center p-4">
              <span className="fa fa-refresh fa-spin fa-2x"></span>
            </p>
          ) : (
            <div className="pt-4 mainVideos">
              <div className="row text-center text-lg-left video-grid w-100">
                {studies.map((study, index) => (
                  <div className="col-sm-6 col-md-4 pb-4" key={index}>
                    <Link to={this.studyLink(study)} className="d-block h-60">
                      <span
                        className="thumbVideo"
                        style={{
                          backgroundImage: "url(" + study.thumbnail + ")",
                        }}
                      ></span>
                      <p className="text-lg-right video-time">{study.time}</p>
                    </Link>
                    <h4 className="text-uppercase">
                      <strong>
                        {study.name} - {study.advertiser}
                      </strong>
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  studies: store.studies.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStudies }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Painel);
