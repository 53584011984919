const video_model = {
    "video_id": "",
    "name": "",
    "time": "",
    "status": "",
    "path": "",
    "thumbnail": "",
    "video_timeline": []
};

export default video_model;
