import api from '../api';

export function reformatDate(dateStr){
    let dateArray = dateStr.split("-")
    return dateArray[2]+ "-" +dateArray[1]+ "-" +dateArray[0]
}

export function save(data) {
    return dispatch => {
        dispatch({
            type: 'LOADING_SAVE_PANELIST',
        });

        data.token = localStorage.getItem('bfToken');
        return api.post('panelist/editar?json=1', data).then(res => {
            dispatch({
                type: "SAVE_PANELIST_COMPLETE",
                "response": res.data
            })
        });
    }
}


export function setCurrentUser(user) {
    return {
        type: "SET_CURRENT_PANELIST",
        user
    };
}
export function loginError(response) {
    return {
        type: "LOGIN_panelist_ERROR",
        response
    }
}
export function logout() {
    localStorage.clear();
    window.location.href = '/';
}

export function changeUserData(data) {
    return dispatch => { 
        dispatch({
            type: "CHANGE_PANELIST_DATA",
            edit_user: data
        });
    }
}

export function login(data) {
    let post_data = {
        email: data.email,
        password: data.password
    }
    
    return dispatch => {
        dispatch({
            type: 'LOADING_LOGIN',
        });
        return api.post('login', post_data).then(res => {
            if (res.data.status === 1) {
                const token = res.data.token;
                api.defaults.headers.common['Authorization'] = token;
                localStorage.setItem('bfToken', token);
                localStorage.setItem('user', JSON.stringify(res.data.user));
                dispatch({
                    type: "panelist_CURRENT_DATA",
                    response: res.data.user
                });
            } else {
                dispatch(loginError(res.data));
            }
            return res
        });
    }
}

export function getInfo() {
    return dispatch => {
        return api.get('user').then(response => {
            dispatch({
                type: "PANELIST_GET",
                response: response.data
            });
        });
    }
}

export function recuperarSenha(post_data) {    
    return dispatch => {
        dispatch({
            type: "LOADING_LEMBRETE",
        });
        return api.post('lembrarSenha/', post_data).then(response => {
            dispatch({
                type: "RESPONSE_LEMBRETE",
                response: response.data
            });
        });
    }
}

export function getUsers() {
    return dispatch => {
        dispatch({
            type: "LOADING_LIST_USERS",
        });
        return api.get('panelists').then(response => {
            dispatch({
                type: "PANELIST_GET_ALL",
                data: response.data
            });
        });
    }
}

export function getUser(user_id){
    return dispatch => {
        return api.get('panelists/'+user_id).then(response => {
            dispatch({
                type: "PANELIST_GET",
                response: response.data
            });
        });
    }
}


export function deleteUser(index, user_id) {
    return dispatch => {
        dispatch({
            type: "RESPONSE_DELETE_panelist",
            index: index
        });
        return api.delete('panelists/'+user_id);
    }
}

export function uploadFile(data) {
    return dispatch => {
        dispatch({
            type: 'PANELIST_LOADING_SAVE',
            loading_save: true
        });
        const formData = new FormData();
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        formData.append('file',data);

        return api.post('uploadFile', formData, config).then(res => {
            dispatch({
                type: 'PANELIST_UPLOAD_DOC',
                "response": res.data
            })
        });
    }
}

export function registerUser(data){
    api.defaults.headers.common['register'] = true;
    return api.post("panelists", data);
}

export function saveUser(data, user_id){
    return dispatch => { 
        dispatch({
            type: "PANELIST_LOADING_SAVE",
            loading_save: true
        });
        if(user_id){
            api.patch("panelists/"+user_id, data).then(response => {
                alert(response.data.msg);
                dispatch({
                    type: "PANELIST_LOADING_SAVE",
                    loading_save: false
                });
            });
        } else {
            api.post("panelists", data).then(response => {
                alert(response.data.msg);
                if(response.data.status === 1){
                    window.location.href = '/panelists/';
                }
                dispatch({
                    type: "PANELIST_LOADING_SAVE",
                    loading_save: false
                });
            });
            
        }
    }
}

export function getUsersAtivos() {
    return api.get('panelists?status=1');
}

export function getPanelistEmotions(panelist_id) {
    return api.get('panelists/'+panelist_id+'/emotions');
}
