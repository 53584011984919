
const initialState = {
    list: null,
    loading_get: true,
    loading_save: false,
    paymentData: {},
    current_index: null
}

export function payments(state = initialState, action) {
    let list = state.list;
    switch (action.type) {
        case "PAYMENTS_LOADING_GET":
            return Object.assign({}, state, {loading_get:true, list: null} );
        case "PAYMENTS_LOADING_SAVE":
                return Object.assign({}, state, {loading_save:action.data} );
        case "PAYMENTS_GET":
            return Object.assign({}, state, {list: action.response, loading_get: false});
        case "PAYMENT_CURRENT_DATA":
            let current_index = state.current_index
            if(action.index != null){
                current_index = action.index
            }
            return Object.assign({}, state, {paymentData: action.paymentData, current_index: current_index});
        case "PAYMENT_UPLOAD":
            let current_data = state.paymentData;
            current_data.proof_of_payment = action.url;
            return Object.assign({}, state, {paymentData: current_data});
        case "PAYMENT_LOADING":
            list[action.index].isLoading = true;
            return Object.assign({}, state, {list: list});
        case "PAYMENTS_UPDATE_LIST_ITEM":
            list[action.index] = action.data;
            return Object.assign({}, state, {list});
        default:
            return state;
    }
}
