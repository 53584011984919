import panelist_model from '../models/panelist'

const initialState = {
    current: panelist_model,
    loading_save: false,
    loading_get: true,
    list: null,
    loading_list: false,
}

export function panelists(state = initialState, action) {
    let list = state.list;
    switch (action.type) {
        case "PANELIST_RESET_CURRENT":
            return Object.assign({}, state, {current: panelist_model});
        case "PANELIST_LOADING_SAVE":
            return Object.assign({}, state, {loading_save: action.loading_save});
        case "PANELIST_CURRENT_DATA":
            return Object.assign({}, state, {current: action.data});
        case "PANELIST_LOADING_GET":
            return Object.assign({}, state, {current: panelist_model, loading_get: true});
        case "PANELIST_GET":
            return Object.assign({}, state, {current: action.response, loading_get: false});
        case "PANELIST_UPLOAD_DOC":
            let dados = state.current;
                dados.doc_file = action.response.url;
            return Object.assign({}, state, {current: dados});
        case "PANELIST_DELETE":
            list.splice(action.index, 1)
            return Object.assign({}, state, {list: list});
        case "PANELIST_ADD":
            list.push(action.data);
            return Object.assign({}, state, {list: list});
        case "PANELIST_LOADING_LIST":
            return Object.assign({}, state, {loading_list: true});
        case "PANELIST_GET_ALL":
            return Object.assign({}, state, {list: action.data});
        default:
            return state;
    }
}