import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import InputMask from 'react-input-mask';
import { getUser, registerUser, changeUserData, uploadFile, getInfo } from '../../actions/panelistActions'
import { getEndereco } from '../../actions/userActions'
import { paises } from '../../models/paises'

export class Register extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            imgPreview: null,
            isProfile: false,
            loading: false,
            status: 0,
            msg: '',
            cpfError: null,
            ageErro: null
        }
        this.file = null;
        this.onChange = this.onChange.bind( this );
        this.blurCEP = this.blurCEP.bind( this );
        this.onBlurCPF = this.onBlurCPF.bind( this );
        this.onChangeNascimento = this.onChangeNascimento.bind( this );
        this.handleFileChange = this.handleFileChange.bind( this );
    }

    validarCPF ( cpf ) {
        cpf = cpf.replace( /[^\d]+/g, '' );
        if ( cpf == '' ) return false;
        if ( cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999" )
            return false;
        // Valida 1o digito	
        var add = 0;
        var rev;
        for ( var i = 0; i < 9; i++ )
            add += parseInt( cpf.charAt( i ) ) * ( 10 - i );
        rev = 11 - ( add % 11 );
        if ( rev == 10 || rev == 11 )
            rev = 0;
        if ( rev != parseInt( cpf.charAt( 9 ) ) )
            return false;
        // Valida 2o digito	
        add = 0;
        for ( i = 0; i < 10; i++ )
            add += parseInt( cpf.charAt( i ) ) * ( 11 - i );
        rev = 11 - ( add % 11 );
        if ( rev == 10 || rev == 11 )
            rev = 0;
        if ( rev != parseInt( cpf.charAt( 10 ) ) )
            return false;
        return true;
    }

    onBlurCPF ( e ) {
        var cpf = e.target.value;
        if ( !this.validarCPF( cpf ) ) {
            this.setState( { cpfError: "Informe um CPF válido" } )
        } else {
            this.setState( { cpfError: null } )
        }
    }

    onChange ( e ) {
        let data = this.props.userData;
        data[e.target.name] = e.target.value;
        this.props.changeUserData( data );
        this.setState( { update: true } )
    }

    onChangeNascimento ( e ) {
        const date1 = new Date( e.target.value );
        const date2 = new Date();
        const diffTime = Math.abs( date2 - date1 );
        const diffDays = Math.ceil( diffTime / ( 1000 * 60 * 60 * 24 ) );
        if ( diffDays < 6570 ) {
            this.setState( { ageErro: "Você precisa ser maior de 18 anos" } )
        } else {

            let data = this.props.userData;
            data[e.target.name] = e.target.value;
            this.props.changeUserData( data );
            this.setState( { ageErro: null } )
        }


    }

    blurCEP ( e ) {
        let data = this.props.userData;
        data[e.target.name] = e.target.value;
        this.props.changeUserData( data );
        this.setState( { update: true } )
        if ( e.target.value.length == 9 ) {
            var cep = data[e.target.name].replace( /[^\d]+/g, '' );
            getEndereco( cep ).then( response => {
                if ( response.data.resultado == "1" ) {
                    data['address_name'] = response.data.tipo_logradouro + " " + response.data.logradouro;
                    data['address_neighborhood'] = response.data.bairro;
                    data['address_city'] = response.data.cidade;
                    data['address_state'] = response.data.uf;
                    data['address_country'] = "BR";
                    this.props.changeUserData( data );
                    this.setState( { update: true } )
                }

            } )
        }
    }

    _save = async ( e ) => {
        e.preventDefault();
        if ( this.state.cpfError == null ) {
            this.setState( { loading: true, msg: '' } )
            if ( this.file ) {
                await this.props.uploadFile( this.file );
            }
            let response = await registerUser( this.props.userData );
            this.setState( { loading: false, status: response.data.status, msg: response.data.msg } )
        }
    }

    handleFileChange ( files ) {
        if ( files && files[0] ) {
            this.file = files[0];
            this.setState( {
                imgPreview: URL.createObjectURL( files[0] )
            } )
        }
    }

    render () {
        const { userData } = this.props;
        const { imgPreview, loading, status, msg, cpfError, ageErro } = this.state;

        return (
            <div>
                <div className="navbar fixed-top navbar-expand-lg">
                    <div className="container-fluid">
                        <div className="navbar-translate">
                            <a className="navbar-brand" href="/"><img src="/assets/img/logo-novo.png" height="55" alt="Brandful" /></a>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{ paddingBottom: '60px' }}>
                    <form onSubmit={this._save}>
                        <div className="card">
                            {msg != "" ?
                                <div className="card-body">
                                    <div className={status == 0 ? 'alert alert-danger' : 'alert alert-info mb-0'}>{msg}</div>
                                </div>
                                :
                                <div className="card-header">
                                    <h4 className="card-title ">Cadastro</h4>
                                </div>
                            }
                            {status == 0 ?
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 text-center doc_file justify-content-center align-items-center">
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>

                                                <label htmlFor="pic_doc" style={{ cursor: "pointer" }}>
                                                    {imgPreview != null ?
                                                        <img src={imgPreview} width="100%" id="doc_file" />
                                                        :
                                                        <span className="btn btn-success btn-round">
                                                            <span className="fa fa-upload"></span> Upload Documento</span>
                                                    }
                                                    <input type="file" name="pic_doc" id="pic_doc" style={{ display: 'none' }} accept="image/*" onChange={( e ) => this.handleFileChange( e.target.files )} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-group col-md-9 mb-0">
                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <label>Nome</label>
                                                    <input type="text" className="form-control" name="name" onChange={this.onChange} value={userData['name']} required />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Sobrenome</label>
                                                    <input type="text" className="form-control" name="lastname" onChange={this.onChange} value={userData['lastname']} required />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Email</label>
                                                    <input type="email" className="form-control" name="email" onChange={this.onChange} value={userData['email']} required />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Senha</label>
                                                    <input type="password" className="form-control" name="password" onChange={this.onChange} value={userData['password']} />
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-0">
                                                    <label>Telefone</label>
                                                    <input type="tel" className="form-control" name="telephone" onChange={this.onChange} value={userData['telephone']} required />
                                                </div>
                                                <div className="form-group col-md-3 mb-0">
                                                    <label>Data de Nascimento</label>
                                                    <input type="date" className="form-control" name="birthday" onChange={this.onChangeNascimento} value={userData['birthday']} required />
                                                    {ageErro != null ? <em className="text-danger">{ageErro}</em> : null}
                                                </div>
                                                <div className="form-group col-md-2 mb-0">
                                                    <label>CPF</label>
                                                    <InputMask type="tel" className="form-control" mask="999.999.999-99" name="cpf" onBlur={this.onBlurCPF} onChange={this.onChange} value={userData['cpf']} required></InputMask>
                                                    {cpfError != null ? <em className="text-danger">{cpfError}</em> : null}
                                                </div>
                                                <div className="form-group col-md-2 mb-0">
                                                    <label>RG</label>
                                                    <input type="text" className="form-control" name="rg" onChange={this.onChange} value={userData['rg']} required />
                                                </div>
                                                <div className="form-group col-md-2 mb-0">
                                                    <label>Sexo</label>
                                                    <select className="form-control" name="gender" onChange={this.onChange} value={userData['gender']}>
                                                        <option value="female">Feminino</option>
                                                        <option value="male">Masculino</option>
                                                    </select>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <h4 className="pt-2"><strong>Endereço</strong></h4>

                                    <div className="form-row">

                                        <div className="form-group col-md-2">
                                            <label>CEP</label>
                                            <InputMask type="tel" className="form-control" name="address_zip" mask="99999-999" onChange={this.onChange} onBlur={this.blurCEP} value={userData['address_zip']} required></InputMask>
                                        </div>
                                        <div className="form-group col-md-8">
                                            <label>Logradouro</label>
                                            <input type="text" className="form-control" name="address_name" onChange={this.onChange} value={userData['address_name']} required />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Número</label>
                                            <input type="tel" className="form-control" name="address_num" onChange={this.onChange} value={userData['address_num']} required />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Complemento</label>
                                            <input type="text" className="form-control" name="address_comp" onChange={this.onChange} value={userData['address_comp']} />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Bairro</label>
                                            <input type="text" className="form-control" name="address_neighborhood" onChange={this.onChange} value={userData['address_neighborhood']} required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Cidade</label>
                                            <input type="text" className="form-control" name="address_city" onChange={this.onChange} value={userData['address_city']} required />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>Estado</label>
                                            <input type="text" className="form-control" name="address_state" onChange={this.onChange} value={userData['address_state']} required />
                                        </div>
                                        <div className="form-group col-md-2">
                                            <label>País</label>
                                            <select className="form-control" name="address_country" onChange={this.onChange} value={userData['address_country']} required>
                                                <option value=""></option>
                                                {paises.map( pais => <option key={pais.sigla} value={pais.sigla}>{pais.nome_pais}</option> )}
                                            </select>

                                        </div>
                                    </div>
                                    <h4 className="pt-2"><strong>Dados Bancários</strong></h4>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label>Número do Banco</label>
                                            <input type="text" className="form-control" name="bank_num" onChange={this.onChange} value={userData['bank_num']} required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Nome do Banco</label>
                                            <input type="text" className="form-control" name="bank_name" onChange={this.onChange} value={userData['bank_name']} required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Agência</label>
                                            <input type="tel" className="form-control" name="bank_agency" onChange={this.onChange} value={userData['bank_agency']} required />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label>Conta</label>
                                            <input type="tel" className="form-control" name="bank_account" onChange={this.onChange} value={userData['bank_account']} required />
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        {status == 0 ?
                            <div className="row justify-content-center">
                                <div className="col-sm-3">
                                    {( loading ? <button className="btn btn-success btn-block disabled" disabled><span className="fa fa-refresh fa-spin"></span></button>
                                        :
                                        <button className="btn btn-success btn-block btn-round">Cadastre-se</button>
                                    )}
                                </div>
                            </div>
                            : null}
                    </form>
                </div>
            </div >
        )
    }
}

const mapStateToProps = store => ( {
    userData: store.panelists.current,
    registerUser: registerUser,
    uploadFile: uploadFile,
    getInfo: getInfo
} )


const mapDispatchToProps = dispatch =>
    bindActionCreators( { getUser, registerUser, changeUserData, uploadFile, getInfo }, dispatch );

export default connect( mapStateToProps, mapDispatchToProps )( Register )
