import { combineReducers } from 'redux';
import { user } from './users';
import { studies } from './studies';
import { videos } from './videos';
import { panelists } from './panelists';
import { configs } from './configs';
import { payments } from './payments';


const rootReducer = combineReducers({
	user: user,
	studies: studies,
	videos: videos,
	panelists: panelists,
	configs: configs,
	payments: payments

});
export default rootReducer;
