import api from "../api";

export function getVideos() {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_GET_VIDEOS",
    });

    return api.get("videos/").then((res) => {
      dispatch({
        type: "GET_ALL_VIDEOS",
        data: res.data,
      });
    });
  };
}



export function searchVideos(item) {
  return (dispatch) => {
    dispatch({
      type: "GET_SEARCH_VIDEOS",
      data: item,
    });
  };
}

export function deleteVideo(index, video_id) {
  return (dispatch) => {
    dispatch({
      type: "RESPONSE_DELETE_VIDEO",
      index: index,
    });
    return api.delete("videos/" + video_id);
  };
}

export function updateVideo(video_id, data) {
  data = { name: data };

  return async (dispatch) => {
    dispatch({
      type: "LOADING_EDIT_USER",
      loading_edit: false,
    });
    api.patch("videos/" + video_id, data).then((response) => {
      alert(response.data.msg);
      dispatch({
        type: "LOADING_EDIT_USER",
        loading_edit: false,
      });
    });
  };
}

export function getVideo(video_id) {
  return async (dispatch) => {
    dispatch({
      type: "LOADING_GET_VIDEO",
    });

    return api.get("video/" + video_id).then((res) => {
      dispatch({
        type: "GET_CAMPAIGN",
        data: res.data,
      });
    });
  };
}

export function playVideo(video_panelist_id) {
  return async (dispatch) => {
    api.post("user/play/" + video_panelist_id).then((response) => {
      if (response.data.viewed_at) {
        window.location.href = "/";
      } else {
        dispatch({
          type: "GET_VIDEO",
          data: response.data,
        });
      }
    });
  };
}

export function webcamUpload(video_panelist_id, img, index) {
  return async (dispatch) => {
    let data = {
      img: img,
      seconds: index,
    };
    api
      .post("user/webcamUpload/" + video_panelist_id, data)
      .then((response) => {
        dispatch({
          type: "WEBCAM_UPLOAD",
          data: response.data,
        });
      });
  };
}

export function viewedVideo(video_panelist_id) {
  return api.post("user/viewed/" + video_panelist_id);
}

