import React, { Component } from "react";
import { connect } from "react-redux";
import * as utils_md5 from "./md5.js";

export class FormUpload extends Component {
  constructor(props) {
    super(props);

    let ext = props.selectedFile.name.split(".").pop();
    let file_name = props.selectedFile.name.replace("." + ext, "");
    this.state = {
      percentage: 0,
      isLoading: false,
      msgError: "",
      msgAviso: "",
      video_name: file_name,
    };

    this.bytes_per_chunk = 1024 * 1024 * 1;
    this.hex_chr = "0123456789abcdef".split("");
    this._files_data = {
      "data-start": 0,
      "data-end": this.getSlicesCount(props.selectedFile),
    };
  }

  onChangeInput = (event) => {
    this.setState({ video_name: event.target.value });
  };

  onClickHandler = () => {
    this.setState({ isLoading: true });
    this.sendRequest(this.props.selectedFile);
  };

  sendRequest(input) {
    this.async(input, 0, 1);
  }

  async(blobs, i, length) {
    let that = this;
    if (i >= length) {
      return false;
    }

    var index = this._files_data["data-start"];

    if (index > 0) index++;

    var start = 0;

    for (var j = 0; j < index; j++) {
      var start = start + this.bytes_per_chunk;
      if (start > blobs[i].size) start = blobs[i].size;
    }

    this.uploadFile(
      blobs,
      index,
      start,
      this._files_data["data-end"],
      function () {
        i++;
        that.async(blobs, i, length);
      }
    );
  }

  uploadFile(blob, index, start, slicesTotal, callback) {
    let that = this;
    var end = start + this.bytes_per_chunk;
    var state_interno = this.state;

    this.user = JSON.parse(localStorage.getItem("user"));
    var token = localStorage.getItem("bfToken");

    if (end > blob.size) end = blob.size;

    this.getChunk(blob, start, end, function (zati) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var error = 0;
        var target = e.target ? e.target : e.srcElement;
        var binary = "";
        var bytes = new Uint8Array(target.result);
        var length = bytes.byteLength;
        for (var i = 0; i < length; i++)
          binary += String.fromCharCode(bytes[i]);

        var hash = utils_md5.md5(binary);

        binary = undefined;

        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            var j = JSON.parse(xhr.response);

            error = j["error"];

            if (error != 1) {
              if (typeof j["error"] !== undefined && j["error"] === "E_HASH") {
                window.setTimeout(function () {
                  that.uploadFile(blob, index, start, slicesTotal, callback);
                }, 100);
              } else {
                if (j["percent"].length != 0) {
                  that.setState({ percentage: j["percent"] });
                }

                index++;
                if (index < slicesTotal) {
                  window.setTimeout(function () {
                    that.uploadFile(blob, index, end, slicesTotal, callback);
                  }, 100);
                } else {
                  that.setState({ msgAviso: j["msg_aviso"] });
                  that.setState({ msgError: "" });
                  callback();

                  that.props.closeModal(true);
                }
              }
            } else {
              that.setState({ msgError: j["error_msg"] });
              that.setState({ msgAviso: "" });
            }
          }
        };

        if (error != 1) {
          xhr.open("post", "https://emotions-ws.brandful.com.br/upload", true);
          //xhr.open( "post", "http://localhost.brandful-emotions.com.br/upload", true );
          xhr.setRequestHeader("Authorization", token);
          xhr.setRequestHeader("X-File-Name", blob.name);
          xhr.setRequestHeader("X-Index", index);
          xhr.setRequestHeader("X-Total", slicesTotal);
          xhr.setRequestHeader("X-Hash", hash);
          xhr.setRequestHeader("X-Titulo", that.state.video_name);
          xhr.send(zati);
        }
      };

      reader.readAsArrayBuffer(zati);
    });
  }

  getChunk(blob, start, end, callback) {
    var chunk;

    if (blob.webkitSlice) chunk = blob.webkitSlice(start, end);
    else if (blob.mozSlice) chunk = blob.mozSlice(start, end);
    else chunk = blob.slice(start, end);

    if (blob.webkitSlice) {
      this.str2ab_blobreader(chunk, function (buf) {
        callback(buf);
      });
    } else callback(chunk);
  }

  str2ab_blobreader(str, callback) {
    var blob;
    var BlobBuilder =
      window.MozBlobBuilder || window.WebKitBlobBuilder || window.BlobBuilder;
    if (typeof BlobBuilder !== "undefined") {
      var bb = new BlobBuilder();
      bb.append(str);
      blob = bb.getBlob();
    } else blob = new Blob([str]);

    var f = new FileReader();
    f.onload = function (e) {
      var target = e.target ? e.target : e.srcElement;
      callback(target.result);
    };
    f.readAsArrayBuffer(blob);
  }

  getSlicesCount(blob) {
    console.log(this.bytes_per_chunk);
    var slices = Math.ceil(blob.size / this.bytes_per_chunk);
    return slices;
  }

  render() {
    const { video_name, isLoading, percentage } = this.state;
    return (
      <div>
        <div className="modal fade show" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title h4">{isLoading ? 'Enviando o Vídeo' : 'Informações do Vídeo'}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.props.closeModal(false)}
                >
                  <i className="material-icons">clear</i>
                </button>
              </div>
              <div className="modal-body">

                {this.state.msgError ? (
                  <div className="card-body">
                    <div className="alert alert-danger text-center col-lg-12">
                      {this.state.msgError}
                    </div>
                  </div>
                ) : null}
                {this.state.msgAviso ? (
                  <div className="card-body">
                    <div className="alert alert-success text-center col-lg-12">
                      {this.state.msgAviso}
                    </div>
                  </div>
                ) : null}

                {!isLoading ? (
                  <div>
                    <div className="form-group">
                      <label>Título do Vídeo</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        name="video_name"
                        onChange={this.onChangeInput}
                        defaultValue={video_name}
                      />
                    </div>
                    <div className="text-center">
                      <button
                        className="btn btn-success btn-round"
                        onClick={this.onClickHandler}
                      >
                        <span className="fa fa-upload mr-1"></span> Iniciar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <div className="progress" style={{ height: "1rem" }}>
                      <div className="progress-bar progress-bar-striped progress-bar-animated" style={{ width: percentage + "%" }}>{percentage}%</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpload);
