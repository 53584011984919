import React, { Component } from "react";
import { saveGroup, getGroup, uploadLogo, removerLogo } from "../../../actions/groupActions";
import { Breadcrumbs } from "../../../components/Breadcrumb";
import Header from "../../../components/Header";

export default class FormGrupos extends Component {

  constructor(props) {
    super(props);
    this.state = {
      groupData: {
        "description": "",
        "logo": "",
        "btn_color": "",
        "btn_textcolor": "",
      },
      loading_logo: false,
      saving: false,
      loading: true
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    let data = this.state.groupData;
    data[e.target.name] = e.target.value;

    this.setState({ groupData: data });
  }

  onChangeLogo = async (e) => {
    let data = this.state.groupData;
    this.setState({ loading_logo: true });
    let response = await uploadLogo(e.target.files[0]);
    if (response.data.status === 1) {
      data['logo'] = response.data.url;
      this.setState({ groupData: data });
    } else {
      window.alert(response.data.msg);
    }
    this.setState({ loading_logo: false });
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      let group_id = this.props.match.params.id;
      if (group_id) {
        let response = await getGroup(group_id);
        let data = response.data;
        data["btn_color"] = (data["btn_color"] == null ? "" : data["btn_color"]);
        data["btn_textcolor"] = (data["btn_textcolor"] == null ? "" : data["btn_textcolor"]);
        data["logo"] = (data["logo"] == null ? "" : data["logo"]);

        this.setState({ groupData: response.data });
      }
    }
    this.setState({ loading: false });
  }

  _save = async (e) => {
    e.preventDefault();
    let response;
    this.setState({ saving: true });
    if (this.props.match.params.id) {
      response = await saveGroup(this.state.groupData, this.props.match.params.id);
    } else {
      response = await saveGroup(this.state.groupData);
    }
    if (response.data.status === 1) {
      window.alert(response.data.msg);
    }
    this.setState({ saving: false });
  };
  _removerLogo = async () => {
    if (window.confirm('Você realmente deseja remover a logo?')) {
      removerLogo(this.state.groupData['logo']);
      let data = this.state.groupData;
      data['logo'] = '';
      this.setState({ groupData: data });
    }
  }

  render() {
    const { groupData, loading } = this.state;
    return <div>
      <Header />
      <Breadcrumbs items={[{ "className": '', 'title': 'Contas' }, { "className": 'active', 'title': (this.props.match.params.id ? "Detalhes" : "Novo") }]} />

      <div className="container" style={{ paddingBottom: "60px" }}>

        {loading ? <p className='text-center'><span className='fa fa-refresh fa-4x fa-spin'></span></p> :
          <form onSubmit={this._save}>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title ">Dados</h4>
              </div>

              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-5">
                    <label>Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      name="description"
                      onChange={this.onChange}
                      value={groupData["description"]}
                      required
                    />
                  </div>
                  <div className=" col-md-4">
                    <label className="d-block" htmlFor="logoInput">Logo {this.state.loading_logo ? (
                      <span className="fa fa-refresh fa-spin"></span>
                    ) : null}</label>
                    {groupData["logo"] != '' ? (<div className="input-group">
                      <div className="input-group-prepend">
                        <button className="btn btn-danger" type="button" onClick={() => this._removerLogo()}>Remover</button>
                      </div>
                      <input type="text" readOnly className="form-control" value={groupData["logo"]} />
                    </div>) :
                      (<input
                        id="logoInput"
                        type="file"
                        className="form-control"
                        name="logo"
                        onChange={this.onChangeLogo}
                      />)
                    }

                  </div>
                  <div className="form-group col-md-1">
                    <label className="d-block">Botões</label>
                    <input
                      type="color"
                      className="d-inline-block"
                      name="btn_color"
                      onChange={this.onChange}
                      value={groupData["btn_color"]}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <label className="d-block">Texto dos Botões</label>
                    <input
                      type="color"
                      className="d-inline-block"
                      name="btn_textcolor"
                      onChange={this.onChange}
                      value={groupData["btn_textcolor"]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3">
                {this.state.saving ? (
                  <button
                    className="btn btn-success btn-block btn-round disabled"
                    disabled
                  >
                    <span className="fa fa-refresh fa-spin"></span>
                  </button>
                ) : (
                  <button className="btn btn-success btn-block btn-round">
                    Salvar
                  </button>
                )}
              </div>
            </div>
          </form>
        }
      </div>
    </div>
  }

}