import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../../components/Header";
import { bindActionCreators } from "redux";
import {
  BootstrapTable,
  TableHeaderColumn,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { getPanelistEmotions } from "../../../actions/panelistActions"
import { emotionsTable } from '../../../models/emotions'
import { Breadcrumbs } from "../../../components/Breadcrumb";

emotionsTable['attention'] = 'Atenção';

export class ReactionsPanelists extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem("bfToken")) {
      window.location.href = "/";
    }
    this.state = {
      images: []
    }
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  async componentDidMount() {
    let result = await getPanelistEmotions(this.props.match.params.id);
    this.setState({ images: result.data });
  }

  thumbnail(cell, item) {
    return <img src={item.img} width="150" />;
  }

  emotion_list(cell, item) {
    let list = <div>
      {Object.keys(item.emotions).map(key => emotionsTable[key] != null ? <p><strong>{emotionsTable[key]}</strong>:  {item.emotions[key]}</p> : null)}
    </div>

    return list;
  }

  render() {
    const { images } = this.state;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": '', 'title': 'Painelistas' }, { "className": 'active', 'title': 'Emoções' }]} />
        <div className="container">


          <div>
            <BootstrapTable
              data={images}
              hover
              search
              options={{
                noDataText: "Nenhuma pesquisa até o momento",
                searchField: this.createCustomSearchField,
              }}
              searchPlaceholder="Procurar..."
              pagination
            >
              <TableHeaderColumn
                isKey
                dataField="time"
                width="150"
                dataAlign="center"
                dataSort={true}
              >
                Tempo
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="img"
                width="250"
                dataFormat={this.thumbnail}>
                IMG
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="emotions"
                dataAlign="left"
                dataFormat={this.emotion_list}
              ></TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.panelists.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getPanelistEmotions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReactionsPanelists);
