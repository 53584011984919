import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { getUsers, deleteUser, saveUser } from "../../actions/userActions";
import {
  BootstrapTable,
  TableHeaderColumn,
  SearchField,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import api from "../../api";
import { Breadcrumbs } from "../../components/Breadcrumb";

export class Administradores extends Component {

  constructor(props) {
    super(props);
    if (!localStorage.getItem("bfToken")) {
      window.location.href = "/";
    }
    this.user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      loading: true,
    }
  }

  async componentDidMount() {
    await this.props.getUsers();
    this.setState({ loading: false });
  }

  delete = async (i, user_id) => {
    if (window.confirm("Você realmente deseja deletar esse usuário?")) {
      await this.props.deleteUser(i, user_id);
      this.setState({ update: true });
    }
  };

  editButton(cell, item) {
    return (
      <Link
        to={"/administradores/edit/" + item.user_id}
        className="btn btn-sm btn-success btn-round btn-block"
      >
        Editar
      </Link>
    );
  }

  deleteButton(cell, item) {
    return (
      <button
        className="btn btn-sm btn-danger btn-round btn-block"
        onClick={() => this.delete(cell, item.user_id)}
      >
        Excluir
      </button>
    );
  }
  goTo(item) {
    window.location.href = "/administradores/edit/" + item.user_id;
  }

  createCustomToolBar = (props) => {
    return (
      <div className="container-fluid">
        <div className="d-flex">
          {this.user.group_id == "1" ? (
            <Link
              to="/administradores/novo"
              className="btn btn-success btn-round"
              style={{ marginTop: "3px" }}
            >
              <i className="fa fa-plus"></i> Novo Usuário
            </Link>
          ) : null}
          <div className="col-sm-4">{props.components.searchPanel}</div>
        </div>
      </div>
    );
  };

  status(cell, item) {
    const toggleUserStatus = (item) => {
      item.status = item.status == "0" ? 1 : 0;

      api.patch("users/" + item.user_id, item);
    };

    return (
      <div className="togglebutton">
        <label>
          <input
            type="checkbox"
            defaultChecked={cell == 1 ? true : false}
            onChange={() => toggleUserStatus(item)}
          />
          <span className="toggle"></span>
        </label>
      </div>
    );
  }

  render() {
    const { users } = this.props;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": 'active', 'title': 'Usuários' }]} />
        <div className="container">
          <h3>
            <strong>Gerenciamento de Usuários</strong>
          </h3>
          {this.state.loading ?
            <p className="text-center p-4">
              <span className="fa fa-refresh fa-spin fa-2x"></span>
            </p>
            :
            <div>
              <BootstrapTable
                data={users}
                hover
                search
                options={{
                  noDataText: "Nenhuma usuário até o momento",
                  toolBar: this.createCustomToolBar,
                }}
                searchPlaceholder="Procurar..."
                pagination
              >
                <TableHeaderColumn
                  isKey
                  dataField="user_id"
                  width="50"
                  dataAlign="center"
                  dataSort={true}
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="name">Nome</TableHeaderColumn>
                <TableHeaderColumn dataField="lastname">
                  Sobrenome
                </TableHeaderColumn>
                <TableHeaderColumn dataField="email">Email</TableHeaderColumn>
                <TableHeaderColumn dataField="phone">Telefone</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="group"
                  width="100"
                  dataAlign="center"
                >
                  Conta
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="status"
                  width="90"
                  dataAlign="center"
                  dataFormat={this.status}
                >
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="ID"
                  width="100"
                  dataAlign="center"
                  dataFormat={this.editButton}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  users: store.user.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsers, deleteUser, saveUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Administradores);
