import React, { Component } from "react";
import Login from "./login.js";
import Play from "./play.js";

export default class Playlist extends Component {


    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            videos: null,
            isLogged: false,
        };
        this.onLogin = this.onLogin.bind(this);
    }

    onLogin() {
        this.setState({ isLogged: true });
    }

    render() {
        const { isLogged } = this.state;
        return (isLogged ? <Play study_id={this.props.match.params.id} /> : <Login study_id={this.props.match.params.id} onLogin={this.onLogin} />);
    }
}

