import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import Header from '../../../components/Header'
import Footer from '../../../components/Footer'
import { getStudy } from '../../../actions/studiesActions'
import { bindActionCreators } from 'redux'
import { Line } from 'react-chartjs-2';
import "./style.css"
import { emotions, colors, emotionsTable } from '../../../models/emotions'
import ReactPlayer from 'react-player'
import { Breadcrumbs } from '../../../components/Breadcrumb';

var videoImgs = [];

export class InfoStudy extends Component {

	opt_chart = {
		tooltips: {
			enabled: true,
			custom: function (tooltipModel) {
				console.log(tooltipModel);
				var tooltipEl = document.getElementById('chartjs-tooltip');

				// Create element on first render
				if (!tooltipEl) {
					tooltipEl = document.createElement('div');
					tooltipEl.id = 'chartjs-tooltip';
					document.getElementsByClassName('chartjs-size-monitor')[0].after(tooltipEl)
				}
				// Hide if no tooltip
				if (tooltipModel.opacity === 0) {
					tooltipEl.style.opacity = 0;
					return;
				}


				if (tooltipModel.body) {
					if (tooltipModel.dataPoints[0].label == '00:00') {
						return;
					}

					let div = document.createElement('div');
					let img = document.createElement('img');
					let loading = document.createElement('span');
					loading.className = 'fa fa-spinner fa-spin position-absolute';
					loading.style.top = '50%';
					loading.style.left = '50%';
					loading.style.transform = 'translate(-50%, -50%)';
					div.className = 'bg-white p-2';
					div.style.borderRadius = '5px';
					div.style.boxShadow = '0px 0px 10px 0px rgba(0,0,0,0.5)';
					div.innerHTML = loading.outerHTML;
					img.src = videoImgs[tooltipModel.dataPoints[0].label];
					img.style.maxWidth = '100%';
					img.style.position = 'relative';

					div.style.width = '200px';
					div.appendChild(img);

					tooltipEl.innerHTML = div.outerHTML;


					// Display, position, and set styles for font
					tooltipEl.style.position = 'absolute';
					tooltipEl.style.zIndex = '100';
					tooltipEl.style.opacity = 1;
					tooltipEl.style.left = (tooltipModel.caretX - 80) + 'px';
					tooltipEl.style.top = tooltipModel.caretY + 'px';
					tooltipEl.style.width = '200px';
				}
				return;
			},
			callbacks: {
				title: function (tooltipItem, data) {
					return;
				},
				label: function (tooltipItem, data) {
					return;
				}
			},
			xPadding: 0,
			yPadding: 0,
			caretPadding: 0,
			caretSize: 0,
			cornerRadius: 10,
		},
		maintainAspectRatio: false,
		scales: {
			yAxes: [{
				display: true,
				ticks: {
					callback: function (label) {
						return label + '%'
					},
					//beginAtZero: true,
					//min: 0,
					fontColor: '#000'
				},
			}],
			xAxes: [{
				ticks: {
					fontSize: 14,
					fontFamily: 'Roboto',
					fontColor: '#000',
				}
			}]
		},
		animation: {
			duration: 500,
			onProgress: this.animationChart,
			onComplete: this.animationChart
		},
		legend: {
			display: false,
		}
	}

	metrics = {
		"percent": "% audiencia",
		"avg": "Média",
		"max": "Máximo"
	}

	genders = {
		"male": "Homem",
		"female": "Mulher"
	}

	ages = {
		"0": "18-24",
		"1": "25-34",
		"2": "35-44",
		"3": "45-54",
		"4": "55+"
	}

	scores = {
		"interest": "Interesse",
		"engajament": "Engajamento",
		"retention": "Retenção",
	}
	chartWidth = 0;
	stepPixels = 0;
	total_seconds = 0;
	current_seconds = 0;
	elm_timeline;

	constructor(props) {
		super(props);
		if (!localStorage.getItem('bfToken')) {
			window.location.href = '/';
		}

		let emotionsActive = Object.keys(emotions);
		//emotionsActive.push("attention");

		this.state = {
			loading_get_study: true,
			currentVideo: 0,
			emotionsActive: emotionsActive,
			metricsActive: ["percent"],
			gendersActive: [],
			agesActive: [],
			scoresActive: [],
			bfi_score: [],
			data_chart: {
				labels: [],
				datasets: []
			},
			timelineProgress: 0,
			urlVideo: null,
		}
		this._finishBuffer = this._finishBuffer.bind(this);

	}

	animationChart() {
		var chartInstance = this.chart,
			ctx = chartInstance.ctx;
		ctx.font = "0px Roboto";
		ctx.fillStyle = "#fff";
		ctx.textAlign = 'center';
		ctx.textBaseline = 'bottom';


		document.getElementById("chart").src = chartInstance.toBase64Image();
	}

	_finishBuffer(urlBlob) {
		this.setState({ urlVideo: urlBlob });
	}

	async componentDidMount() {
		let study_id = this.props.match.params.id
		videoImgs = [];
		await this.props.getStudy(study_id);
		this.props.infoData.videos.map((video, index) => {
			// console.log(video.results.bfi_score);
			this.state.bfi_score[index] = video.results.bfi_score;
		});

		this.currentVideoData(0);
	}

	currentVideoData(index) {
		let currentVideo = this.props.infoData.videos[index];

		let labels = [];
		if (currentVideo.timeline.length > 30) {
			currentVideo.timeline.splice(0, -1)
		}
		currentVideo.timeline.map((time) => {
			labels.push(time);
			if (currentVideo.video_timeline) {
				videoImgs[time] = currentVideo.video_timeline[time];
			}
		});

		this.state.urlVideo = currentVideo.path;
		this.state.data_chart.labels = labels;
		this.state.data_chart.datasets = [];
		let current_metric = (this.state.metricsActive[0] != "percent" ? this.state.metricsActive[0] : "avg");


		this.state.emotionsActive.map((emotion_key) => {

			let data_emotion = [];
			if (this.state.gendersActive.length == 0 || this.state.gendersActive.length == 2) {
				Object.keys(currentVideo.results.emotions).map((time) => {
					data_emotion.push(currentVideo.results.emotions[time][emotion_key][current_metric]);
				});
			} else {
				if (this.state.gendersActive.indexOf('male') > -1) {
					Object.keys(currentVideo.results.genders.male).map((time) => {
						data_emotion.push(currentVideo.results.genders.male[time][emotion_key][current_metric]);
					});
				} else {
					Object.keys(currentVideo.results.genders.female).map((time) => {
						data_emotion.push(currentVideo.results.genders.female[time][emotion_key][current_metric]);
					});
				}
			}
			this.state.data_chart.datasets.push({
				label: emotions[emotion_key],
				fill: false,
				lineTension: 0.1,
				backgroundColor: colors[emotion_key][0],
				borderColor: colors[emotion_key][1],
				borderCapStyle: 'butt',
				borderDash: [],
				borderDashOffset: 0.0,
				borderJoinStyle: 'miter',
				pointBorderColor: colors[emotion_key][1],
				pointBackgroundColor: '#fff',
				pointBorderWidth: 5,
				pointHoverRadius: 5,
				pointHoverBackgroundColor: colors[emotion_key][1],
				pointHoverBorderColor: 'rgba(220,220,220,1)',
				pointHoverBorderWidth: 5,
				pointRadius: 2,
				pointHitRadius: 10,
				data: data_emotion
			})
		});

		this.setState({ loading_get_study: false })
	}

	activeTab = (event, href) => {
		event.preventDefault();
		this.setState({ currentTab: href });
	}

	formata_data = data => {
		return data.split('-').reverse().join('/');
	}

	btnVideoActive = key => {
		return (this.state.currentVideo == key ? 'btn btn-success btn-round disabled' : 'btn btn-success btn-round ')
	}

	isEnabled = (key, val) => {
		return (this.state[key].indexOf(val) > -1 ? 'd-flex btn btn-block btn-success btn-round disabled' : 'd-flex btn btn-block btn-success btn-round hidden-print')
	}

	activeVideo = key => {
		if (this.state.currentVideo != key) {
			this.setState({ currentVideo: key })
			this.currentVideoData(key);
		}
	}

	_onPlay(e) {
		this.chartWidth = parseFloat(window.getComputedStyle(document.querySelector('.chartjs-render-monitor')).width);
	}



	_onEnded() {
		setTimeout(function () {
			//$( '.timeline' ).css( 'left', '65px' )
		}, 2000)

	}


	filterToggle = (key, val, reload_graph = true) => {
		let filter = this.state[key];

		if (filter.indexOf(val) > -1) {
			filter.splice(filter.indexOf(val), 1);
		} else {
			filter.push(val);
		}
		this.state[key] = filter;
		this.setState({});
		if (reload_graph) {
			this.currentVideoData(this.state.currentVideo)
		}
	}

	toggleMetrics = (metric) => {
		// console.log(metric);
		this.setState({ loading_get_study: true })
		this.state.metricsActive = [metric];
		this.currentVideoData(this.state.currentVideo)
	}


	async filterToggleFetch(key, val) {
		// console.log(key);
		// console.log(val);
		let study_id = this.props.match.params.id
		let filter = this.state[key];

		if (filter.indexOf(val) > -1) {
			filter.splice(filter.indexOf(val), 1);
		} else {
			filter.push(val);
		}
		// console.log("Array do filtro "+filter)
		this.state[key] = filter;

		this.setState({ loading_get_study: true })
		//let params = 'gender=' + this.state.gendersActive.join( ',' )
		let params = 'age=' + this.state.agesActive.join(',')

		await this.props.getStudy(study_id, params);
		this.currentVideoData(this.state.currentVideo)

		console.log(Object.keys(this.scores));
	}

	render() {

		const { infoData } = this.props;
		const { loading_get_study, currentVideo, data_chart, metricsActive, emotionsActive, gendersActive, scoresActive, urlVideo, agesActive, bfi_score } = this.state;
		return (
			<div>
				<Header />

				<Breadcrumbs items={[{ "className": '', 'title': 'Estudos' }, { "className": 'active', 'title': 'Resultados' }]} />

				{loading_get_study ? <p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p> :
					(infoData.name ?
						<div id={"result_video_" + infoData.videos[currentVideo].video_id}>
							<div className="container-fluid pb-3">
								<h2 className="title h3 mb-0"> {infoData.advertiser} - {infoData.name}</h2>
							</div>
							<div className="container-fluid">
								<div id="filterVideos" className="hidden-print">{infoData.videos.map((item, key) => {
									return <button id={"video_" + item.video_id} className={this.btnVideoActive(key)} onClick={() => { this.activeVideo(key) }} key={key}>{item.name}</button>
								})}</div>

								<div className="row">
									<div className="col-sm-9 row">
										<div className="col-sm-2 flex-column">
											<h4 className="ml-3"><strong>Emoções</strong></h4>
											{Object.keys(emotions).map((key) =>
											(key != "attention" ?
												<button className={this.isEnabled('emotionsActive', key)} onClick={() => { this.filterToggle('emotionsActive', key) }} key={key}>{emotions[key]}</button> : null)
											)}
										</div>

										<div className="col-sm-2 flex-column">
											<h4 className="ml-3"><strong>Atenção</strong></h4>
											<button className={this.isEnabled('emotionsActive', "attention")} onClick={() => { this.filterToggle('emotionsActive', "attention") }}>Atenção</button>
										</div>

										<div className="col-sm-2" id="filterMetrics">
											<h4 className="ml-3"><strong>Métricas</strong></h4>
											{Object.keys(this.metrics).map((key) =>
												<button id={key} className={this.isEnabled('metricsActive', key)} onClick={() => { this.toggleMetrics(key) }} key={key}>{this.metrics[key]}</button>
											)}
										</div>
										<div id="filterGender" className="col-sm-2">
											<h4 className="ml-3"><strong>Gênero</strong></h4>
											{Object.keys(this.genders).map((key) =>
												<button className={this.isEnabled('gendersActive', key)} onClick={() => { this.filterToggle('gendersActive', key) }} key={key}>{this.genders[key]}</button>
											)}
										</div>
										<div id="filterAge" className="col-sm-2">
											<h4 className="ml-3"><strong>Idade</strong></h4>

											{Object.keys(this.ages).map((key) =>
												<button className={this.isEnabled('agesActive', key)} onClick={() => { this.filterToggleFetch('agesActive', key) }} key={key}>{this.ages[key]}</button>
											)}
										</div>
										<div id="filterScores" className="col-sm-2">
											<h4 className="ml-3"><strong>Scores</strong></h4>
											{/* {console.log(this.ages)} */}
											{Object.keys(this.scores).map((key) =>
												<button className={this.isEnabled('scoresActive', key)} onClick={() => { this.filterToggle('scoresActive', key, false) }} key={key}>{this.scores[key]}</button>
											)}
										</div>
									</div>
									<div className="col-sm-3">
										<div className="pt-5">
											{urlVideo ?
												<ReactPlayer className='react-player'
													url={urlVideo}
													controls
													width='100%'
													height="240"
													onPlay={this._onPlay}
													onPause={this._onPause}
													onProgress={this._onProgress}
													onEnded={this._onEnded} />
												: <p className="text-center"><span className="fa fa-refresh fa-spin fa-2x"></span></p>
											}
										</div>
									</div>
								</div>
								{infoData.videos[currentVideo].total_views > 0 ?
									<div className="card" id="result">
										<h4 className="pl-3">Resultados</h4>
										{infoData.videos[currentVideo].total_views > 0 ?
											<div className="indicadores">
												<div className="badge badge-primary text-left pl-3 pr-3 pt-2">
													<p className="mb-0"><span>BFI <span className="fa fa-caret-right"></span></span></p>
													<strong className="h3 text-white">{bfi_score[currentVideo].total}</strong><span>/{infoData.videos[currentVideo].results.avgScores.bfi}</span>
												</div>
											</div>
											: null}
										<div className="col-md-10 offset-md-1 d-none d-print-block">
											<img src="" id="chart" className="img-responsive" />
										</div>
										<div className="col-md-10 offset-md-1 d-print-none">
											<Line data={data_chart} options={this.opt_chart} height={350} />
										</div>

										{metricsActive.map(metric_key =>
											<div className="pt-5" key={metric_key} id={"result_" + metric_key}>
												<table className="table table-bordered">
													<thead>
														<tr>
															<th width="70"></th>
															<th width="100">Visualizações</th>
															<th width="100">Métrica</th>
															{emotionsActive.map(emotion_key =>
																<th key={emotion_key} className={emotion_key == 'attention' ? "text-center bg-gray" : "text-center"}><span className="d-inline-block rounded-circle" style={{ background: colors[emotion_key][1], width: "10px", height: "10px" }}></span> {emotionsTable[emotion_key]}</th>
															)}
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																{agesActive.map(age_key =>
																	<p key={this.ages[age_key]} className="text-muted"><em>{this.ages[age_key]}</em></p>
																)}
															</td>
															<td>{infoData.videos[currentVideo].results.views.total} views</td>
															<td>{this.metrics[metric_key]}</td>
															{infoData.videos[currentVideo].results.views.total > 0 ?
																emotionsActive.map(emotion_key =>
																	<td width="150" key={emotion_key} className={emotion_key == 'attention' ? "text-center bg-gray" : "text-center"}>
																		{metric_key != 'percent' ?
																			<div className="td_progress">
																				{infoData.videos[currentVideo].results.gender_emotions['general'][emotion_key][metric_key]}%
																				{Object.keys(emotions).indexOf(emotion_key) > -1 ?
																					<div className="progress">
																						<div className="progress-bar" style={{ width: infoData.videos[currentVideo].results.gender_emotions['general'][emotion_key][metric_key] + '%', backgroundColor: colors[emotion_key][1] }}></div>
																					</div>
																					: null}
																			</div>
																			: infoData.videos[currentVideo].results.gender_emotions['general'][emotion_key][metric_key] + "%"}
																	</td>
																) : null}
														</tr>
														{
															gendersActive.indexOf('male') > -1 ?
																<tr>
																	<td>
																		Homem
																		{agesActive.map(age_key =>
																			<p key={this.ages[age_key]} className="text-muted"><em>{this.ages[age_key]}</em></p>
																		)}
																	</td>
																	<td>{infoData.videos[currentVideo].results.views.male} views</td>
																	<td>{this.metrics[metric_key]}</td>
																	{emotionsActive.map((emotion_key) =>
																		<td width="170" key={emotion_key} className={emotion_key == 'attention' ? "text-center bg-gray" : "text-center"}>
																			{Object.keys(infoData.videos[currentVideo].results.gender_emotions).indexOf('male') > -1 ?
																				(metric_key != 'percent' ?
																					<div className="td_progress">
																						{infoData.videos[currentVideo].results.gender_emotions['male'][emotion_key][metric_key]}%
																						{Object.keys(emotions).indexOf(emotion_key) > -1 ?
																							<div className="progress">
																								<div className="progress-bar" style={{ width: infoData.videos[currentVideo].results.gender_emotions['male'][emotion_key][metric_key] + '%', backgroundColor: colors[emotion_key][1] }}></div>
																							</div>
																							: null}
																					</div>
																					: infoData.videos[currentVideo].results.gender_emotions['male'][emotion_key][metric_key] + "%")

																				: <span>0</span>}
																		</td>
																	)}
																</tr> : null
														}
														{gendersActive.indexOf('female') > -1 ?
															<tr>
																<td>
																	Mulher
																	{agesActive.map(age_key =>
																		<p key={this.ages[age_key]} className="text-muted"><em>{this.ages[age_key]}</em></p>
																	)}
																</td>
																<td>{infoData.videos[currentVideo].results.views.female} views</td>
																<td>{this.metrics[metric_key]}</td>
																{emotionsActive.map(emotion_key =>
																	<td width="170" key={emotion_key} className={emotion_key == 'attention' ? "text-center bg-gray" : "text-center"}>
																		{Object.keys(infoData.videos[currentVideo].results.gender_emotions).indexOf('female') > -1 ?
																			(metric_key != 'percent' ?
																				<div className="td_progress">
																					{infoData.videos[currentVideo].results.gender_emotions['female'][emotion_key][metric_key]}%
																					{Object.keys(emotions).indexOf(emotion_key) > -1 ?
																						<div className="progress">
																							<div className="progress-bar" style={{ width: infoData.videos[currentVideo].results.gender_emotions['female'][emotion_key][metric_key] + '%', backgroundColor: colors[emotion_key][1] }}></div>
																						</div>
																						: null}
																				</div>
																				: infoData.videos[currentVideo].results.gender_emotions['female'][emotion_key][metric_key] + "%")

																			: <span>0</span>}
																	</td>
																)}
															</tr> : null
														}

													</tbody>
												</table>
											</div>
										)}

										{scoresActive.length > 0 ?
											<div className="pt-5">
												<table className="table table-bordered">
													<thead>
														<tr>
															<th width="70"></th>
															<th width="100">Visualizações</th>
															{scoresActive.map(score_key =>
																<th key={score_key} id={score_key}>{this.scores[score_key]}</th>
															)}
														</tr>
													</thead>
													<tbody>
														<tr>
															<td></td>
															<td>{infoData.videos[currentVideo].results.views.total} views</td>
															{scoresActive.map(score_key =>
																<td width="150" key={score_key}>
																	{infoData.videos[currentVideo].results[score_key]['general']} <span className='small text-muted'>/ {infoData.videos[currentVideo].results.avgScores['general'][score_key]}</span>
																</td>
															)}
														</tr>
														{
															gendersActive.indexOf('male') > -1 ?
																<tr>
																	<td>Homem</td>
																	<td>{infoData.videos[currentVideo].results.views.male} views</td>
																	{scoresActive.map(score_key =>
																		<td width="150" key={score_key}>
																			{infoData.videos[currentVideo].results[score_key]['male']} <span className='small text-muted'>/ {infoData.videos[currentVideo].results.avgScores['male'][score_key]}</span>
																		</td>
																	)}
																</tr> : null
														}
														{
															gendersActive.indexOf('female') > -1 ?
																<tr>
																	<td>Mulher</td>
																	<td>{infoData.videos[currentVideo].results.views.female} views</td>
																	{scoresActive.map(score_key =>
																		<td width="150" key={score_key}>
																			{infoData.videos[currentVideo].results[score_key]['female']} <span className='small text-muted'>/ {infoData.videos[currentVideo].results.avgScores['female'][score_key]}</span>
																		</td>
																	)}
																</tr> : null
														}
													</tbody>
												</table>
											</div>
											: null}
									</div>
									: <div className="alert alert-warning my-4 text-center">Não há dados para serem analisados até o momento.</div>}
							</div>
						</div>
						: <Redirect to="/studies/" />)
				}
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = store => ({
	infoData: store.studies.current,
})

const mapDispatchToProps = dispatch =>
	bindActionCreators({ getStudy }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(InfoStudy)
