export const emotions = {
    'happiness': 'Felicidade',
    'sadness': 'Tristeza',
    'surprise': 'Surpresa',
    'fear': 'Medo',
    'anger': 'Raiva',
    'disgust': 'Desgosto',
    'attention': 'Atenção',
};

export const emotionsTable = {
    'happiness': 'Felicidade',
    'sadness': 'Tristeza',
    'surprise': 'Surpresa',
    'fear': 'Medo',
    'anger': 'Raiva',
    'disgust': 'Desgosto',
    'attention': 'Atenção',
};

export const colors = {
    'happiness': ['rgba(13, 71, 161,0.4)', 'rgba(13, 71, 161, 1)'],
    'sadness': ['rgba(198, 40, 40,0.4)', 'rgba(198, 40, 40, 1)'],
    'surprise': ['rgba(123, 31, 162,0.4)', 'rgba(123, 31, 162, 1)'],
    'fear': ['rgba(75,192,192,0.4)', 'rgba(75,192,192, 1)'],
    'anger': ['rgba(175, 180, 43,0.4)', 'rgba(175, 180, 43, 1)'],
    'disgust': ['rgba(93, 64, 55,0.4)', 'rgba(93, 64, 55, 1)'],
    'attention': ['rgba(99, 99, 99,0.4)', 'rgba(99, 99, 99, 1)'],
};