import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { loginFace } from "../../actions/userActions";
import { bindActionCreators } from "redux";
import Webcam from "react-webcam";
import {getStudy, eventVideo} from '../../actions/watchActions';


const videoConstraints = {
    width: 450,
    height: 340,
    facingMode: "user"
};


export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            response_login: false,
            infoData: null,
            termos: true,
            redirect_loading: false
        };
        this.webcam = null;
        this._onUserMedia = this._onUserMedia.bind(this);
        this.changeTermos = this.changeTermos.bind(this);
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    async componentDidMount() {
        //document.body.classList.add("login-page");
        if(this.props.study_video_id){
            this.study_video_id = this.props.study_video_id;
            let response = await getStudy(this.study_video_id);
            this.setState({
              infoData: response.data
            })
        }
    }

    capture = async () => {
        const imageSrc = this.webcam.getScreenshot();
        if(imageSrc != null){
            this.setState({ loading: true });
            let response = await loginFace({img: imageSrc, study_video_id: this.study_video_id});
            this.setState({
                response_login: response.data,
            });
            if(response.data.status){
                localStorage.setItem('panelistToken', response.data.token);
                this.props.onLogin();
            } else {
                this.setState({loading: false})
            }
        } else {
            window.setTimeout(() => this.capture(), 3000);
        }
    };


    openWebcam() {
      if(this.state.termos){
        this.setState({ showWebcam: true, loading: false, response_login: false, redirect_loading: true });
      } else {
        alert("Você precisa aceitar os termos de uso para iniciar a pesquisa.")
      }
    }

    _onUserMedia(){
        window.setTimeout(() => this.capture(), 3000);
    }

    closeModal() {
        this.setState({ showWebcam: false, redirect_loading: false });
    }
    changeTermos(e){
      this.setState({
        termos: e.target.checked
      });
    }

    cleanUrl(url){
      this.setState({
        redirect_loading: true
      });
      let tags = url.match(/({{[a-z_0-9]+}})/gi);
      let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(','').replaceAll(')','');
      let params = new URLSearchParams(query_string);
      
      tags.map((val) => {
        url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
      });

      const redirect_url = new URL(url)

      let searchParams = new URLSearchParams(redirect_url.search);
      let post = {"study_video_id": this.study_video_id};
      for(var pair of searchParams.entries()) {
        post[pair[0]] = pair[1];
      }
      eventVideo(post).then(() =>{
        window.location.href = url;
      });
    }

    render() {
      const {response_login, infoData, termos, redirect_loading} = this.state;
      return (
          infoData == null ? <div>
            <p className="text-center py-4">
              <span className="fa fa-4x fa-refresh fa-spin"></span>
            </p>
          </div> 
          :
          <div>
            <nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
                <div className="container-fluid">
                  <div className="navbar-translate mb-2 mt-2">
                    <img src="/assets/img/logo-netquest.png" height="55" alt="Brandful" />
                  </div>
                
                  <div className="collapse navbar-collapse hidden-print">
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">Termos de Uso</a>
                      </li>
                    </ul>
                  </div>
                </div>
            </nav>

            <ol className="breadcrumb py-4">
                <li className="breadcrumb-item font-weight-bold">Painelista | Bem Vindo</li>
            </ol>
            <div className="container-fluid" style={{paddingBottom: '60px'}}>
              <h3 className="pt-4" style={{overflowWrap: "break-word"}}><strong>{infoData['name']}</strong></h3>

              <p>Você está prestes a participar de uma pesquisa de análise por meio do monitoramento da sua face e reconhecimento de suas emoções. Para que tudo dê certo, basta que assista ao vídeo descrito abaixo da forma mais natural possível, seguindo os seguintes passos:</p>
              <ul className="list-unstyled text-muted font-weight-bold">
                  <li>Passo 1: Verifique se o ambiente está bem iluminado e sua webcam está em correto funcionamento.</li>
                  <li>Passo 2: Clique sobre o botão iniciar abaixo para começar a pesquisa.</li>
                  <li>Passo 3: Autorize a ativação da sua webcam para identificação inicial.</li>
                  <li>Passo 4: Assista o comercial até o fim.</li>
                  <li>Passo 5: Aguarde o processamento e a mensagem de sucesso.</li>
                  <li>Passo 6: Aguarde o carregamento do próximo comercial e volte ao passo 4 (apenas em pesquisas com mais de 1 filme)</li>
              </ul>
              <p className="pt-4 pb-8"><strong>*Importante:</strong> Sua webcam será utilizada apenas durante a exibição do vídeo.</p>

              <p className="font-weight-bold">Minhas Tarefas</p>
              <div className="card d-md-none">
                <img src={infoData['thumbnail']} className="card-img-top" alt={infoData['video_id']} />
                <div className="card-body">
                  <dl className="row">
                    <dt className="col-sm-3">Tipo</dt>
                    <dd className="col-sm-9">{infoData['type']}</dd>
                    <dt className="col-sm-3">Requerimento</dt>
                    <dd className="col-sm-9">{infoData['requirement']}</dd>
                    <dt className="col-sm-3">Duração</dt>
                    <dd className="col-sm-9">{infoData['time']}</dd>
                  </dl>
                  {/* <label htmlFor="termos">
                       <span className="pr-2"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span> 
                      Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                  </label> */}
                  {redirect_loading ? <div className="text-center"><span className="fa fa-4x fa-refresh fa-spin"></span></div> : 
                    <div className="text-center">
                    
                    <button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"}  onClick={() => this.openWebcam()}>
                      <span className="fa fa-eye"></span> Participar
                    </button>
                    {infoData['redirect_reject'] != null ?
                        <p><button onClick={() => {this.cleanUrl(infoData['redirect_reject'])}} className="btn btn-round btn-block">Recusar Pontos</button></p>
                    : null}
                  </div>}
                </div>
              </div>
              <table className="table table-hover table-bordered d-none d-md-table">
                <thead>
                  <tr>
                    <th colSpan="2">ID</th>
                    <th>Tipo</th>
                    <th>Requerimento</th>
                    <th>Duração</th>
                    <th width="100"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{infoData['video_id']}</td>
                    <td><img src={infoData['thumbnail']} height="150px" /></td>
                    <td>{infoData['type']}</td>
                    <td>{infoData['requirement']}</td>
                    <td>{infoData['time']}</td>
                    {/* <td width="150" className="text-center">
                      <label htmlFor="termos">
                        <span className="d-block"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span>
                        Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                      </label>
                    </td> */}
                    <td>
                      {redirect_loading ? <div className="text-center"><span className="fa fa-refresh fa-spin"></span></div> :
                      <div className="text-center">
                        <button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"}  onClick={() => this.openWebcam()}>
                          <span className="fa fa-eye"></span> Participar
                        </button>
                        {infoData['redirect_reject'] != null ?
                          <p><button onClick={() => {this.cleanUrl(infoData['redirect_reject'])}} className="btn btn-round btn-block">Recusar Pontos</button></p>
                        : null}
                      </div>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          {this.state.showWebcam ? (
            <div>
              <div className="modal fade show" style={{ display: "block" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Identificação Facial</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={() => this.closeModal()}
                      >
                        <i className="material-icons">clear</i>
                      </button>
                    </div>
                    <div className="modal-body">
                      {response_login !== false ? 
                        <div className={response_login.status === 1 ? "alert alert-success" : "alert alert-danger"}>
                          {response_login.msg}
                        </div>
                      : null}

                      {this.state.loading ? (
                        <p className="text-center">
                          <span className="fa fa-2x fa-refresh fa-spin"></span>
                        </p>
                      ) : (response_login === false ? <Webcam
                            audio={false}
                            height={340}
                            width={450}
                            mirrored={true}
                            screenshotFormat="image/jpeg"
                            screenshotQuality={1}
                            minScreenshotHeight={340}
                            minScreenshotWidth={450}
                            ref={this.setRef}
                            onUserMedia={this._onUserMedia}
                            videoConstraints={videoConstraints}
                        /> : null)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="modal-backdrop fade show"
                onClick={() => this.closeModal()}
              ></div>
            </div>
          ) : null}
        </div>
      );
    }
}
