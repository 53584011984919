import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../../components/Header";
import {
  getUser,
  getUserTypes,
  saveUser,
  changeUserData,
} from "../../../actions/userActions";
import { Breadcrumbs } from "../../../components/Breadcrumb";
import { MyStudies } from './studies'

export class FormAdministradores extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    let data = this.props.userData;
    data[e.target.name] = e.target.value;
    this.props.changeUserData(data);
    this.setState({ update: true });
  }
  async componentDidMount() {
    await this.props.getUserTypes();

    this.setState({ update: true });
    if (this.props.match.params.id) {
      let user_id = this.props.match.params.id;
      if (user_id) {
        await this.props.getUser(user_id);
        this.setState({ update: true });
      }
    } else {
      let data = this.props.userData;
      //data["group_id"] = this.props.groups[0].group_id;
      this.props.changeUserData(data);
    }
  }

  _save = async (e) => {
    e.preventDefault();
    if (this.props.match.params.id) {
      this.props.saveUser(this.props.userData, this.props.match.params.id);
    } else {
      this.props.saveUser(this.props.userData);
    }
  };

  render() {
    const { userData, groups } = this.props;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": '', 'title': 'Usuários' }, { "className": 'active', 'title': (this.props.match.params.id ? "Detalhes" : "Novo") }]} />

        <div className="container" style={{ paddingBottom: "60px" }}>
          <form onSubmit={this._save}>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title ">Dados</h4>
              </div>

              <div className="card-body">
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label>Conta</label>
                    <select
                      className="form-control"
                      name="group_id"
                      onChange={this.onChange}
                      value={userData["group_id"]}
                      required
                    >
                      <option value=''>Selecione uma Conta</option>
                      {groups.map((group) => {
                        return (
                          <option value={group.group_id} key={group.group_id}>
                            {group.description}
                          </option>
                        );
                      })}
                    </select>

                  </div>
                  <div className="form-group col-md-5">
                    <label>Nome</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={this.onChange}
                      value={userData["name"]}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Sobrenome</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      onChange={this.onChange}
                      value={userData["lastname"]}
                      required
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      onChange={this.onChange}
                      value={userData["email"]}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Telefone</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="phone"
                      onChange={this.onChange}
                      value={userData["phone"]}
                      required
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label>Senha</label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      onChange={this.onChange}
                      value={userData["password"]}
                    />
                  </div>
                </div>
                <MyStudies user_id={this.props.match.params.id} userData={userData} onChange={this.onChange} />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-3">
                {this.props.loading ? (
                  <button
                    className="btn btn-success btn-block disabled"
                    disabled
                  >
                    <span className="fa fa-refresh fa-spin"></span>
                  </button>
                ) : (
                  <button className="btn btn-success btn-block btn-round">
                    Salvar
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  userData: store.user.edit_user,
  loading: store.user.loading_save,
  saveUser: saveUser,
  groups: store.user.groups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getUserTypes, getUser, saveUser, changeUserData },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormAdministradores);
