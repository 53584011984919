const panelist_model = {
    "panelist_id": '',
    "doc_file": '',
    "name": '',
    "lastname": '',
    "email": '',
    "password": '',
    "telephone": '',
    "birthday": '',
    "cpf": '',
    "rg": '',
    "gender": '',
    "address_name": '',
    "address_num": '',
    "address_comp": '',
    "address_neighborhood": '',
    "address_city": '',
    "address_state": '',
    "address_country": '',
    "address_zip": '',
    "bank_num": '',
    "bank_name": '',
    "bank_agency": '',
    "bank_account": '',
    "invited_panelist_id": null,
    "status": 1,
    "video_panelist_id": null,
};

export default panelist_model;