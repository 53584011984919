import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteVideo } from "../../../actions/videosActions";
import { bindActionCreators } from "redux";
import "./style.css";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";

export class VideoGrid extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false, title: this.props.title };
    this.onChange = this.onChange.bind(this);
    this.fecharModal = this.fecharModal.bind(this);
  }

  _showVideo = (e) => {
    e.preventDefault();
    this.setState({ showModal: true });
  };

  fecharModal = () => {
    this.setState({ showModal: false });
  };

  handleClick = () => {
    this.props.onClick();
  };

  handleUpdate = () => {
    var title = this.state.title;
    this.props.onSubmit(title);
  };

  onChange(e) {
    this.setState({ title: e.target.value });
  }

  render() {
    const { showModal } = this.state;

    return (
      <div className="col-sm-6 col-md-4 pb-4">
        <a onClick={this._showVideo} href="#" className="d-block h-60">
          <span
            className="thumbVideo"
            style={{ backgroundImage: "url(" + this.props.thumbnail + ")" }}
          ></span>
          <p className="text-lg-right video-time">{this.props.time}</p>
        </a>
        {showModal ? (
          <div>
            <div className="modal fade show" style={{ display: "block" }}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title h4">Editar Vídeo</h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.fecharModal()}
                    >
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <video
                          src={this.props.path}
                          controls={true}
                          width="100%"
                          controlsList="nodownload nofullscreen noremoteplayback"
                          disablePictureInPicture
                        ></video>
                        <form className="form" onSubmit={this.handleUpdate}>
                          <label>Título do Vídeo</label>
                          <input
                            type="name"
                            className="form-control"
                            placeholder="Nome"
                            required
                            name="name"
                            onChange={this.onChange}
                            value={this.state.title}
                          />
                          <button className="btn btn-success btn-round">
                            Atualizar
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-round pull-right"
                            onClick={this.handleClick}
                          >
                            Excluir
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show"></div>
          </div>
        ) : null}
        <h4 className="text-uppercase">
          <strong>{this.props.title}</strong>
        </h4>
        {this.props.user ?
          <p className="text-muted">Enviado por: <b>{this.props.user}</b></p>
          : null}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteVideo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoGrid);
