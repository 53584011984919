import React, { Component } from "react";
import './index.css'
export default class Footer extends Component {
  getYear = () => {
    let data = new Date();
    return data.getFullYear();
  };
  render() {
    return (
      <footer className="mainFooter">
        <strong>
          Brandful {this.getYear()} - All Rights Reserved.
        </strong>
      </footer>
    );
  }
}
