import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { getGroups, deleteGroup } from "../../actions/groupActions";
import {
  BootstrapTable,
  TableHeaderColumn,
  SearchField,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import api from "../../api";
import { Breadcrumbs } from "../../components/Breadcrumb";

export default class Grupos extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem("bfToken")) {
      window.location.href = "/";
    }
    this.user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      loading: true,
      groups: []
    };
  }

  async componentDidMount() {
    let response = await getGroups();

    this.setState({ groups: response.data, loading: false });
  }

  delete = async (group_id, item) => {
    if (window.confirm("Você realmente deseja deletar esse grupo?")) {
      deleteGroup(group_id);
      let data = this.state.groups;
      let index = data.indexOf(item)
      data.splice(index, 1);
      this.setState({ groups: data });
    }
  };

  editButton(cell, item) {
    if (cell == 1) {
      return null;
    }
    return (
      <Link
        to={"/grupos/edit/" + item.group_id}
        className="btn btn-sm btn-success btn-round btn-block"
      >
        Editar
      </Link>
    );
  }

  deleteButton(cell, item, _onCLick) {
    if (cell == 1) {
      return null;
    }
    return (
      <button
        className="btn btn-sm btn-danger btn-round btn-block"
        onClick={() => _onCLick(cell, item)}
      >
        Excluir
      </button>
    );
  }

  createCustomToolBar = (props) => {
    return (
      <div className="container-fluid">
        <div className="d-flex">
          {this.user.group_id == "1" ? (
            <Link
              to="/grupos/novo"
              className="btn btn-success btn-round"
              style={{ marginTop: "3px" }}
            >
              <i className="fa fa-plus"></i> Nova Conta
            </Link>
          ) : null}
          <div className="col-sm-4">{props.components.searchPanel}</div>
        </div>
      </div>
    );
  };


  render() {
    const { groups } = this.state;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": '', 'title': 'Grupos' }]} />
        <div className="container">
          <h3>
            <strong>Gerenciamento de Contas</strong>
          </h3>
          {this.state.loading ?
            <p className="text-center p-4">
              <span className="fa fa-refresh fa-spin fa-2x"></span>
            </p>
            :
            <div>
              <BootstrapTable
                data={groups}
                hover
                search
                options={{
                  noDataText: "Nenhuma pesquisa até o momento",
                  toolBar: this.createCustomToolBar,
                }}
                searchPlaceholder="Procurar..."
                pagination
              >
                <TableHeaderColumn
                  isKey
                  dataField="group_id"
                  width="50"
                  dataAlign="center"
                  dataSort={true}
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="description">
                  Nome
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="group_id"
                  width="100"
                  dataAlign="center"
                  dataFormat={this.editButton}
                ></TableHeaderColumn>
                <TableHeaderColumn
                  dataField="group_id"
                  width="100"
                  dataAlign="center"
                  dataFormat={this.deleteButton}
                  formatExtraData={this.delete}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          }
        </div>
      </div>
    );
  }
}