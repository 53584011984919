import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

import { changeUserData } from '../../../actions/userActions';
import api from '../../../api';

export class MyStudies extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      studiesLoaded: false,
      users: [],
      "studies": [],
      "user_studies": [],
    }

    this.addStudyId = this.addStudyId.bind(this);

  }

  componentDidMount() {
    this.listSearch();
  }

  async listSearch() {
    let user = JSON.parse(localStorage.getItem('user'));

    let result = await api.get(`studies/index/${user['group_id']}`)
    this.setState({
      "studies": result.data
    })
    this.filterStudies();
    this.setState({ update: true });
  }

  filterStudies() {
    this.state.studies.filter((study) => {
      this.state.user_studies.filter((user_study) => {
        if (study.ID === user_study.ID) {
          this.state.studies.splice(study, 1);
        }
      })
    });
  }



  addStudyId(userdata) {

    let studies = this.state.studies;

    studies.map((study, key) => {
      if (study.ID === userdata.ID) {//antes == depois ===                
        studies.splice(key, 1);
      }
    });
    this.state.user_studies.push(userdata);
    this.props.onChange({ 'target': { 'name': 'studies', 'value': this.state.user_studies } })
  }

  _novoContato = () => {
    this.setState({ showModal: true });
  }
  closeModal = () => {
    this.setState({ showModal: false });
  }

  addButton(cell, item, extra) {

    return <button className="btn btn-sm btn-success btn-round" type="button" onClick={() => { extra(item) }}>Adicionar</button>

  }

  _removeContato = index => {
    let data = this.state.user_studies[index]
    this.state.studies.push(data);
    this.state.user_studies.splice(index, 1);
    this.props.onChange({ 'target': { 'name': 'studies', 'value': this.state.user_studies } })
  }

  isEdit() {
    this.state["user_studies"] = this.props.userData['studies'];
    this.filterStudies()
  }

  render() {

    const { showModal, studies, user_studies } = this.state;
    const { userData } = this.props;
    if (userData.user_id) {
      this.isEdit()
    }


    console.log(userData);

    return (
      <fieldset className="mt-2">
        <h4 className="card-title">Adicionar Pesquisa</h4>

        <button type="button" className="btn btn-success btn-round" onClick={this._novoContato}><i className="fa fa-plus"></i> Adicionar</button>


        {showModal ?
          <div>

            <div className="modal fade show" style={{ display: "block" }}>
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Adicionar contato para essa pesquisa</h5>

                    <button type="button" className="close" onClick={() => this.closeModal()}>
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <BootstrapTable data={studies} hover search options={{ noDataText: "Nenhuma pesquisa encontrado", sizePerPageDropDown: '' }} searchPlaceholder="Procurar..." pagination>
                      <TableHeaderColumn isKey dataField='ID' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='advertiser'>Anunciante</TableHeaderColumn>
                      <TableHeaderColumn dataField='name'>Titulo</TableHeaderColumn>
                      <TableHeaderColumn formatExtraData={this.addStudyId} dataField='study_id' width='100' dataAlign='center' dataFormat={this.addButton}></TableHeaderColumn>
                    </BootstrapTable>
                  </div>

                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show" onClick={() => this.closeModal()}></div>
          </div>
          : null}

        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Estudo</th>
              <th>Anunciante</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              user_studies.map((item, i) => {

                return (
                  <tr key={i}>

                    <td>{item.ID}</td>
                    <td>{item.advertiser}</td>
                    <td>{item.name}</td>
                    <td className='text-right'><button type="button" className="btn btn-danger btn-sm btn-circle" onClick={() => this._removeContato(i)}><i className="fa fa-minus"></i></button></td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>

      </fieldset>

    )
  }
}
const mapStateToProps = store => ({
  userData: store.user.edit_user,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeUserData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyStudies)
