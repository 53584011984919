import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Login from "./pages/Login";
import Painel from "./pages/Painel";
import Administradores from "./pages/Administradores";
import Videos from "./pages/Videos";
import Studies from "./pages/Studies";
import FormStudy from "./pages/Studies/FormStudy";
import InfoStudy from "./pages/Studies/InfoStudy";
import FormAdministradores from "./pages/Administradores/FormAdministradores";
import { getInfo, logout } from "./actions/userActions";
import Panelists from "./pages/Panelists/";
import ReactionsPanelist from "./pages/Panelists/ReactionsPanelist";
import Watch from "./pages/Watch";
import Register from "./pages/Register";
import Playlist from "./pages/Playlist";
import Grupos from "./pages/Grupos";
import FormGrupos from "./pages/Grupos/FormGrupos";

class App extends Component {
  constructor(props) {
    super(props);
    if (localStorage.getItem("bfToken")) {
      this.props.getInfo();
    }
  }

  render() {
    let home = "/painel/";
    let user = {
      group_id: 0,
    };
    if (localStorage.getItem("bfToken")) {
      user = JSON.parse(localStorage.getItem("user"));

    }
    return (
      <div>
        <Router>
          <>
            <Route path="/" exact strict render={() =>
              !localStorage.getItem("bfToken") ? (
                <Login />
              ) : (
                <Redirect to={home} />
              )
            }
            />
            <Route
              path="/videos/"
              exact
              strict
              render={() =>
                localStorage.getItem("bfToken") ? <Videos /> : <Redirect to="/" />
              }
            />

            <Route path="/logout/" exact strict render={() => logout()} />

            <Route path="/register/" exact strict component={Register} />
            <Route path="/watch/:id" exact strict component={Watch} />
            <Route path="/playlist/:id" exact strict component={Playlist} />

            {user.group_id == "1" ?
              <>
                <Route
                  path="/painel/"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <Painel />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/studies/"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <Studies />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/studies/create"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <FormStudy />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/studies/edit/:id"
                  exact
                  strict
                  component={FormStudy}
                />
                <Route
                  path="/studies/info/:id"
                  exact
                  strict
                  component={InfoStudy}
                />
                <Route
                  path="/grupos/"
                  exact
                  strict
                  component={Grupos}
                />
                <Route
                  path="/grupos/edit/:id"
                  exact
                  strict
                  component={FormGrupos}
                />
                <Route
                  path="/grupos/novo"
                  exact
                  strict
                  component={FormGrupos}
                />
                <Route
                  path="/administradores/"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <Administradores />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/panelists/"
                  exact
                  strict
                  component={Panelists}
                />
                <Route
                  path="/panelists/reactions/:id"
                  exact
                  strict
                  component={ReactionsPanelist}
                />
                <Route
                  path="/administradores/edit/:id"
                  exact
                  strict
                  component={FormAdministradores}
                />
                <Route
                  path="/administradores/novo"
                  exact
                  strict
                  component={FormAdministradores}
                />
              </> :
              <div>
                <Route
                  path="/painel/"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <Painel />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/studies/"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <Studies />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />

                <Route
                  path="/studies/create"
                  exact
                  strict
                  render={() =>
                    localStorage.getItem("bfToken") ? (
                      <FormStudy />
                    ) : (
                      <Redirect to="/" />
                    )
                  }
                />
                <Route
                  path="/studies/edit/:id"
                  exact
                  strict
                  component={FormStudy}
                />
                <Route
                  path="/studies/info/:id"
                  exact
                  strict
                  component={InfoStudy}
                />
              </div>}
          </>
        </Router>
      </div >
    );
  }
}

const mapStateToProps = (store) => ({
  isLogged: store.user.isLogged,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getInfo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
