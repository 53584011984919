import study_model from '../models/study'

const initialState = {
    current: study_model,
    loading_save: false,
    loading_get_study: true,
    list: null,
    loading_list_studies: false,
    paineis: [],
    products: [],
}

export function studies(state = initialState, action) {
    let list = state.list;
    switch (action.type) {
        case "RESET_CURRENT":
            return Object.assign({}, state, { current: study_model });
        case "LOADING_SAVE_STUDY":
            return Object.assign({}, state, { loading_save: action.loading_save });
        case "STUDY_CURRENT_DATA":
            return Object.assign({}, state, { current: action.studyData });
        case "LOADING_GET_STUDY":
            return Object.assign({}, state, { current: study_model, loading_get_study: true });
        case "GET_STUDY":
            return Object.assign({}, state, { current: action.data, loading_get_study: false });
        case "DELETE_STUDY":
            list.splice(action.index, 1)
            return Object.assign({}, state, { list: list });
        case "ADD_STUDY":
            list.push(action.data);
            return Object.assign({}, state, { list: list });
        case "LOADING_LIST_STUDIES":
            return Object.assign({}, state, { loading_list_studies: true });
        case "GET_ALL_STUDIES":
            return Object.assign({}, state, { list: action.data });
        case "RESPONSE_PAINEIS":
            return Object.assign({}, state, { paineis: action.response });
        case "RESPONSE_PRODUCTS":
            return Object.assign({}, state, { products: action.response });
        default:
            return state;
    }
}