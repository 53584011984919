import configs_model from '../models/configs'

const initialState = {
    current: configs_model,
    list: null,
    loading_get: true,
    loading_save: false,
}

export function configs(state = initialState, action) {
    let list = state.list;
    switch (action.type) {
        case "CONFIGS_LOADING_GET":
            return Object.assign({}, state, {loading_get:true} );
        case "CONFIGS_LOADING_SAVE":
                return Object.assign({}, state, {loading_save:action.data} );
        case "CONFIGS_GET":
            return Object.assign({}, state, {current: action.response, loading_get: false});
        case "CONFIGS_SAVE_DATA":
            return Object.assign({}, state, {current: action.data});
        default:
            return state;
    }
}
