import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import api from "../../api";
import { getStudiesSearch } from "../../actions/studiesActions";
import { bindActionCreators } from "redux";
import DataTable from "react-data-table-component";

export class Header extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem("user"));

    let colorButton = user.btn_color;
    let colorTextButton = user.btn_textcolor;
    let css = "";
    if (colorButton) {
      css = "<style>.btn.btn-success, .btn-sm, .alert.alert-info,.badge.badge-primary,.btn.btn-success{background:" + colorButton + " !important;color:" + colorTextButton + " !important;}";

      css += ".dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover, .dropdown-menu a:active, .dropdown-menu a:focus, .dropdown-menu a:hover { background-color: " + (!colorButton ? "" : colorButton) + "!important;}"

      css += ".nav-pills .nav-item .nav-link.active { color: " + (!colorTextButton ? "" : colorTextButton) + ";background-color: " + (!colorButton ? "" : colorButton) + ";}";
      css += ".pagination > .page-item.active > a, .pagination > .page-item.active > a:focus, .pagination > .page-item.active > a:hover, .pagination > .page-item.active > span, .pagination > .page-item.active > span:focus, .pagination > .page-item.active > span:hover {	background-color: " + colorButton + ";border-color: " + colorButton + "!important;}}";
      css += '</style>';
    }

    this.state = {
      navClass: "navbar fixed-top navbar-expand-lg",
      logoNav: false,
      menuItens: [],
      menuState: {},
      search_visible: "none",
      showSearchForm: false,
      inlineCss: css,
      logo: user.logo,
    };



    this.columns = [
      {
        name: "ID",
        selector: "ID",
        wrap: true,
        compact: true,
        width: "50px",
      },
      {
        name: "Anunciante",
        selector: "advertiser",
        sortable: true,
        wrap: true,
        width: "50%",
      },
      {
        name: "Estudo",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Início",
        selector: "start",
        wrap: true,
      },
    ];

  }

  async componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.handleScroll);
    var response = await api.get("menu");
    var menuState = {};
    for (var i = 0; i <= response.data.length; i++) {
      menuState[i] = "";
    }
    this.setState({ menuSurltate: menuState, menuItens: response.data });
    //await this.props.getStudiesSearch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goTo = (item) => {
    window.location.href = "/studies/info/" + item.ID;
  };

  _toggleMenu(e, key) {
    e.preventDefault();
    var menuState = this.state.menuState;
    menuState[key] = menuState[key] ? "" : "show";
    this.setState({ menuState: menuState });
  }
  _toggleMenuMobile(e) {
    e.preventDefault();

    var elm = document.querySelector(".navbar-toggler");
    var html_elm = document.getElementsByTagName("html")[0];
    var isOpen = html_elm.className;

    if (isOpen) {
      elm.className = elm.className.replace("toggled", "");
      html_elm.className = html_elm.className.replace("nav-open", "");
    } else {
      elm.className = elm.className + " toggled";
      html_elm.className = "nav-open";
    }
  }

  renderMenu = () => {
    return this.state.menuItens.map((value, key) => {
      return value.submenu ? (
        <li
          key={key}
          className={this.state.menuState[key] + " nav-item dropdown"}
          onMouseEnter={(event) => {
            this._toggleMenu(event, key);
          }}
          onMouseLeave={(event) => {
            this._toggleMenu(event, key);
          }}
        >
          <a
            className="nav-link dropdown-toggle"
            target={value.target ? value.target : "_self"}
            href={value.url}
            onClick={(event) => {
              this._toggleMenu(event, key);
            }}
            dangerouslySetInnerHTML={{ __html: value.label }}
          ></a>
          <div className="ripple-container"></div>
          <div className={this.state.menuState[key] + " dropdown-menu"}>
            {value.submenu.map((subitem, k) => {
              return (
                <a
                  key={k}
                  href={subitem.url}
                  className="dropdown-item"
                  target={value.target ? value.target : "_self"}
                >
                  {subitem.label}
                </a>
              );
            })}
          </div>
        </li>
      ) : (
        <li className="nav-item" key={key}>
          <a
            className="nav-link"
            target={value.target ? value.target : ""}
            href={value.url}
            dangerouslySetInnerHTML={{ __html: value.label }}
          ></a>
        </li>
      );
    });
  };

  onChange = (search) => {
    let q = search.target.value.toLowerCase();
    let studies_filtered = [];
    if (q == "") {
      this.setState({
        search_visible: "none",
        studies_filtered: studies_filtered,
      });
    } else {
      this.studies.map((item) => {
        if (item.name.toLowerCase().indexOf(q) > -1) {
          studies_filtered.push(item);
        }
      });
      this.setState({
        search_visible: "block",
        studies_filtered: studies_filtered,
      });
    }
  };

  render() {
    const { studies } = this.props;
    this.studies = studies;
    const { showSearchForm, inlineCss, logo, btn_color, btn_textcolor } = this.state;



    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: inlineCss }}></div>
        <nav className={this.state.navClass} id="sectionsNav">
          <div className="container">
            <div className="navbar-translate">

              <a className="navbar-brand" href="/">
                <img src={logo} height="55" alt="Brandful" id="logoImg" />
              </a>


              <button
                className="navbar-toggler"
                type="button"
                onClick={(e) => {
                  this._toggleMenuMobile(e);
                }}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            {showSearchForm ? (
              <form className="form-inline hidden-print">
                <div className="form-group bmd-form-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Buscar Estudo"
                    onChange={(val) => {
                      this.onChange(val);
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-white btn-raised btn-fab btn-round"
                >
                  <i className="material-icons">search</i>
                </button>
                {studies === null ? null : (
                  <DataTable
                    columns={this.columns}
                    data={this.state.studies_filtered}
                    noHeader
                    className="suggest"
                    fixedHeader
                    fixedHeaderScrollHeight="50vh"
                    dense
                    onRowClicked={this.goTo}
                    style={{ display: this.state.search_visible }}
                  />
                )}
              </form>
            ) : null}
            <div className="collapse navbar-collapse hidden-print">
              <ul className="navbar-nav ml-auto">{this.renderMenu()}</ul>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  loading_list_studies: store.studies.loading_list_studies,
  studies: store.studies.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getStudiesSearch }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
