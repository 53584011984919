import React, { Component } from 'react'
import { connect } from 'react-redux'
import Header from '../../../components/Header'
import Study from './study.js'
import StudyVideos from './studyVideos.js'
import StudyAddUsers from './studyAddUsers.js'
import { bindActionCreators } from 'redux';

import { changeStudyData, addStudy, getStudy, updateStudy, resetStudyData, toogleStudy } from '../../../actions/studiesActions'
import { listUserofGroupID } from '../../../actions/userActions'
import { Breadcrumbs } from '../../../components/Breadcrumb'
import './studyAddUsers.css';

export class FormStudy extends Component {
    constructor(props) {
        super(props);
        if (!localStorage.getItem('bfToken')) {
            window.location.href = '/';
        }
        this.state = {
            pageTitle: "Novo",
            loadingPage: false
        };

    }
    _save = async (e) => {
        console.log(this.props.studyData)
        e.preventDefault();
        let data = this.props.studyData;
        if (data.videos[0].video_id == "") {
            alert("Por favor, adicione pelo menos 1 vídeo em seu estudo.")
        } else {
            if (this.props.match) {
                this.props.updateStudy(data, this.props.match.params.id);
            } else {
                await this.props.addStudy(data);
            }
        }

        this.setState({ "update": true })
    }

    async componentDidMount() {
        //chamando usurios do grupo 2 com o listUserofGroupID
        let group_id = JSON.parse(localStorage.user).group_id
        await this.props.listUserofGroupID(group_id)
        if (this.props.match) {
            let study_id = this.props.match.params.id
            if (study_id) {
                this.setState({ loadingPage: true })
                await this.props.getStudy(study_id);
                this.setState({ "update": true, "pageTitle": "Editar", "loadingPage": false })
            }
        } else {
            await this.props.resetStudyData();
            this.setState({ "update": true, "pageTitle": "Novo" })
        }
    }

    toggle(study_id, current_status) {
        let confirm_message = (current_status != "cancelled" ? "Deseja desativar o estudo?" : "Deseja ativar o estudo?");
        if (window.confirm(confirm_message)) {
            this.setState({ loading: true })
            toogleStudy(study_id).then(async (response) => {
                this.props.history.push('/studies/')
            });
        }

    }

    render() {
        const { loading, studyData } = this.props;
        const { pageTitle, loadingPage } = this.state;

        return (
            <div>
                <Header />
                <Breadcrumbs items={[{ "className": 'active', 'title': 'Estudos' }]} />

                {loadingPage ? <p className='text-center'><span className='fa fa-refresh fa-4x fa-spin'></span></p> :

                    <div className="container" style={{ paddingBottom: '60px' }}>
                        <div className='row'>
                            <div className='col-md-6'>
                                <h2 className="title m-0 h3"> {pageTitle} Estudo</h2>
                            </div>
                            {pageTitle == "Editar" ? <div className='col-md-6 text-right'>
                                {studyData.status == "cancelled" ?
                                    <button className="btn btn-sm btn-warning btn-round" onClick={() => {
                                        this.toggle(studyData.study_id, studyData.status);
                                    }}>
                                        Habilitar Estudo
                                    </button>
                                    :
                                    <button className="btn btn-sm btn-danger btn-round" onClick={() => {
                                        this.toggle(studyData.study_id, studyData.status);
                                    }}>
                                        Cancelar Estudo
                                    </button>
                                }
                            </div> : null}
                        </div>

                        <form onSubmit={this._save}>
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Cadastro</h4>
                                </div>
                                <div className="card-body">
                                    <Study />
                                    {/* ADD USERS ON STUDY */}
                                    <StudyAddUsers />
                                    <StudyVideos />

                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-sm-3">
                                    {loading ?
                                        <button className="btn btn-success btn-block btn-round disabled" disabled><span className="fa fa-spin fa-refresh"></span></button>
                                        :
                                        <button className="btn btn-success btn-block btn-round">Salvar Estudo</button>
                                    }
                                </div>
                            </div>
                        </form>

                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = store => ({
    studyData: store.studies.current,
    loading: store.studies.loading_save,
    listUserofGroupID: store.user.user_group_id,
    addStudy: addStudy,
    updateStudy: updateStudy,
    resetStudyData: resetStudyData
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ changeStudyData, addStudy, getStudy, updateStudy, resetStudyData, listUserofGroupID }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormStudy)
