import api from '../api';

export function playVideo() {
  //api.headers["Authorization"] = localStorage.getItem('panelistToken');
  return api.get("watch/play", {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  });
}

export function playPlaylistVideo(study_hash) {
  //api.headers["Authorization"] = localStorage.getItem('panelistToken');
  return api.get("playlist/play/" + study_hash, {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  });
}

export function start_at() {
  return api.get("watch/start_at", {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  });
}

export function getStudy(video_hash) {
  return api.get("watch/get/" + video_hash, {
    headers: {
      "Register": true,
      "Authorization": localStorage.getItem('panelistToken')
    }
  });
}

export function getPlaylist(study_hash) {
  return api.get("playlist/get/" + study_hash, {
    headers: {
      "Register": true,
      "Authorization": localStorage.getItem('panelistToken')
    }
  });
}

export function webcamUpload(img, index, study_video_id = null) {
  let data = {
    img: img,
    seconds: index,
    study_video_id: study_video_id
  };
  return api.post("watch/webcamUpload", data, {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  })
}

export function viewedVideo(study_video_id = null) {
  return api.post("watch/viewed", {
    study_video_id: study_video_id
  }, {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  });
}

export function eventVideo(params) {
  return api.post("watchEvents/", params, {
    headers: { "Authorization": localStorage.getItem('panelistToken') }
  });
}
