import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { getUsers, deleteUser, saveUser } from "../../actions/panelistActions";
import {
  BootstrapTable,
  TableHeaderColumn,
  SearchField,
} from "react-bootstrap-table";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import api from "../../api";
import { Breadcrumbs } from "../../components/Breadcrumb";

export class Panelists extends Component {
  constructor(props) {
    super(props);
    if (!localStorage.getItem("bfToken")) {
      window.location.href = "/";
    }
    this.user = JSON.parse(localStorage.getItem("user"));
  }

  async componentDidMount() {
    await this.props.getUsers();
    this.setState({ update: true });
  }

  delete = async (i, user_id) => {
    if (window.confirm("Você realmente deseja deletar esse usuário?")) {
      await this.props.deleteUser(i, user_id);
      this.setState({ update: true });
    }
  };

  editButton(cell, item) {
    return (
      <Link
        to={"/panelists/reactions/" + item.video_panelist_id}
        className="btn btn-sm btn-success btn-round btn-block"
      >
        Ver
      </Link>
    );
  }

  deleteButton(cell, item) {
    return (
      <button
        className="btn btn-sm btn-danger btn-round btn-block"
        onClick={() => this.delete(cell, item.user_id)}
      >
        Excluir
      </button>
    );
  }
  goTo(item) {
    window.location.href = "/panelists/edit/" + item.panelist_id;
  }

  status(cell, item) {
    const toggleUserStatus = (item) => {
      item.status = item.status == 0 ? 1 : 0;

      api.patch("panelists/" + item.panelist_id, item);
    };

    return (
      <div className="togglebutton">
        <label>
          <input
            type="checkbox"
            defaultChecked={cell == 1 ? true : false}
            onChange={() => toggleUserStatus(item)}
          />
          <span className="toggle"></span>
        </label>
      </div>
    );
  }

  createCustomToolBar = (props) => {
    return (
      <div className="container-fluid">
        <div className="d-flex">
          {this.user.group_id == "1" ? (
            <Link
              to="/payments/"
              className="btn btn-success btn-round"
              style={{ marginTop: "3px" }}
            >
              <span class="material-icons" style={{ fontSize: "17px" }}>
                monetization_on
              </span>{" "}
              Pagamentos Solicitados
            </Link>
          ) : null}
          <div className="col-sm-4">{props.components.searchPanel}</div>
        </div>
      </div>
    );
  };
  createCustomSearchField = (props) => {
    return (
      <div>
        <SearchField
          className="form-control text-uppercase campo-busca"
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
        />
        <button type="button" className="btn-busca">
          <i className="material-icons">search</i>
        </button>
      </div>
    );
  };

  render() {
    const { users } = this.props;
    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": '', 'title': 'Painelistas' }]} />
        {users == null ?
          <p className="text-center p-4"><span className="fa fa-refresh fa-spin fa-4x"></span></p>
          :
          <div className="container">
            <h3>
              <strong>Gerenciamento de Painelistas</strong>
            </h3>

            <div>
              <BootstrapTable
                data={users}
                hover
                search
                options={{
                  noDataText: "Nenhuma pesquisa até o momento",
                  //toolBar: this.createCustomToolBar,
                }}
                searchPlaceholder="Procurar..."
                pagination
              >
                <TableHeaderColumn
                  isKey
                  dataField="panelist_id"
                  width="50"
                  dataAlign="center"
                  dataSort={true}
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn dataField="age">Idade</TableHeaderColumn>
                <TableHeaderColumn dataField="gender">
                  Sexo
                </TableHeaderColumn>
                <TableHeaderColumn dataField="study">Estudo</TableHeaderColumn>
                <TableHeaderColumn dataField="video">
                  Vídeo
                </TableHeaderColumn>

                <TableHeaderColumn
                  dataField="panelist_id"
                  width="100"
                  dataAlign="center"
                  dataFormat={this.editButton}
                ></TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        }
      </div>

    );
  }
}

const mapStateToProps = (store) => ({
  users: store.panelists.list,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsers, deleteUser, saveUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Panelists);
