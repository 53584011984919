import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import {
  getVideos,
  deleteVideo,
  updateVideo,
  searchVideos,
} from "../../actions/videosActions";
import { VideoGrid } from "./VideoGrid";
import { bindActionCreators } from "redux";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import "./style.css";

import FormUpload from "./formUpload.js";
import { Breadcrumbs } from "../../components/Breadcrumb";

export class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videos_visible: "none",
      currentPage: 1,
      videosPerPage: 10,
      total: 0,
      isOpen: false,
      selected: undefined,
      toggle: false,
      selectedFile: null,
      percentage: 0,
      msgError: "",
      msgAviso: "",
      showModal: false,
      video_name: "",
      isFilter: false,
      muda: 0,
      videosList: props.videos,
    };

    this.toggle = this.toggle.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.perPages = [10, 20, 30];
  }

  updatePerpage(e, page) {
    this.setState({
      videosPerPage: parseInt(page),
      currentPage: 1,
    });
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  }

  async componentDidMount() {
    if (this.props.videos == null) {
      let group_id = JSON.parse(localStorage.user).group_id
      let user_id = JSON.parse(localStorage.user).user_id

      await this.props.getVideos(group_id, user_id);
      this.setState({ videosList: this.props.videos });
    }
  }

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  closeModal = async (reload) => {
    this.setState({ selectedFile: null });
    document.getElementById("fileUpload").value = null;
    if (reload) {
      await this.props.getVideos();
      this.setState({ videosList: this.props.videos });
    }
  };

  fileSelectedHandler = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  onChange = (search) => {
    const { videos } = this.props;

    let q = search.target.value.toLowerCase();
    let videos_filtered = [];
    if (q == "") {
      this.setState({
        videosList: videos,
      });
    } else {
      videos.all_videos.map((item) => {
        if (item.name.toLowerCase().indexOf(q) > -1) {
          videos_filtered.push(item);
        }
      });
      this.setState({
        videosList: {
          all_videos: videos_filtered,
          total: videos_filtered.length,
        },
      });
    }
  };

  updateState(prop, value) {
    this.setState({
      selected: value,
      videosPerPage: value,
    });
  }

  delete = async (i, video_id) => {
    if (window.confirm("Você realmente deseja deletar esse vídeo?")) {
      await this.props.deleteVideo(i, video_id);
      await this.props.getVideos();
    }
  };

  update = async (video_id, dados) => {
    await this.props.updateVideo(video_id, dados);
  };
  page = (e, number) => {
    e.preventDefault();
    this.setState({ currentPage: number });
  };

  render() {
    const { videos, show_search_videos } = this.props;
    const { selectedFile, videosPerPage, videosList, currentPage } = this.state;
    this.videos = videos;

    this.show_search_videos = show_search_videos;

    const pageNumbers = [];
    let rows = [];

    if (videosList != null) {
      for (let i = 1; i <= Math.ceil(videosList.total / videosPerPage); i++) {
        pageNumbers.push(i);
      }

      console.log(videosPerPage);

      for (
        let i = (currentPage - 1) * videosPerPage;
        i < videosPerPage * currentPage;
        i++
      ) {
        let video = videosList.all_videos[i];
        if (video) {
          rows.push(
            <VideoGrid
              key={i}
              onSubmit={(titulo) => this.update(video.video_id, titulo)}
              onClick={(index, id) => this.delete(1, video.video_id)}
              chave={video.video_id}
              path={video.path}
              title={video.name}
              thumbnail={video.thumbnail}
              time={video.time}
              user={video.user_name}
            />
          );
        }
      }
    }

    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;

    return (
      <div>
        <Header />
        <Breadcrumbs items={[{ "className": 'active', 'title': 'Meus Vídeos' }]} />
        <div className="container">
          <h3>
            <strong>Vídeos Disponíveis</strong>
          </h3>
        </div>
        <div className="container search_form_videos">
          <div className="navbar navbar-expand-lg px-2">

            <label htmlFor="fileUpload" className="m-0">
              <span className="btn btn-success btn-round">
                <span className="fa fa-upload mr-1"></span> Novo Upload
              </span>
              <input
                type="file"
                name="fileUpload"
                id="fileUpload"
                className="d-none"
                accept="video/*"
                onChange={this.fileSelectedHandler}
              />
            </label>
            {selectedFile != null ? (
              <FormUpload
                selectedFile={selectedFile}
                closeModal={this.closeModal}
              />
            ) : null}

            <form className="ml-4 form-inline hidden-print">
              <div className="form-group bmd-form-group">
                <input
                  type="text"
                  className="form-control busca"
                  placeholder="Buscar Vídeo"
                  onChange={(val) => {
                    this.onChange(val);
                  }}
                />
              </div>
              <button
                type="submit"
                className="btn btn-white btn-raised btn-fab btn-round"
              >
                <i className="material-icons">search</i>
              </button>
            </form>
          </div>
        </div>

        <div className="container">
          {videosList == null ? (
            <p className="text-center p-5">
              <span className="fa fa-2x fa-refresh fa-spin"></span>
            </p>
          ) : videosList.total == 0 ? (
            <div className="pt-3">
              <p className="alert alert-info">Nenhum resultado encontrado</p>
            </div>
          ) : (
            <div className="pt-3">
              <div className="row text-center text-lg-left video-grid">
                {rows.map((video, key) => video)}
              </div>
              <div className="react-bs-table-pagination">
                <div className="row">
                  <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <div className="dropdown" onClick={this.toggleOpen}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                      >
                        {this.state.selected !== undefined
                          ? this.state.selected
                          : "Exibir Linhas"}
                      </button>

                      <div className={menuClass}>
                        {this.perPages.map((page) => (
                          <a
                            key={page}
                            onClick={(e) => this.updatePerpage(e, page)}
                            className="dropdown-item"
                            href="#"
                          >
                            {page}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-6 col-sm-6 col-lg-6">
                    <ul className="react-bootstrap-table-page-btns-ul pagination">
                      {pageNumbers.map((number) => (
                        <li
                          key={number}
                          className={
                            number == currentPage
                              ? "active page-item"
                              : "page-item"
                          }
                          title={number}
                        >
                          <a
                            onClick={(e) => this.page(e, number)}
                            href="!#"
                            className="page-link"
                          >
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div >
    );
  }
}

const mapStateToProps = (store) => ({
  infoData: store.videos.current,
  videos: store.videos.list,
  show_search_videos: store.videos.show_search_videos,
  current_page: store.videos.current_page,
  loading_get_videos: store.videos.loading_get_videos,
  filter: store.videos.filter,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getVideos, deleteVideo, updateVideo, searchVideos },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Videos);
