import videos_model from "../models/videos";

const initialState = {
  current: videos_model,
  list: null,
  current_page: 0,
  loading_get_videos: true,
  loading_edit_videos: true,
  show_search_videos: false,
  loading_upload_videos: false,
};

export function videos ( state = initialState, action ) {
  let list = state.list;
  switch ( action.type ) {
    case "GET_VIDEO":
      return Object.assign( {}, state, {
        current: action.data,
        loading_get_videos: false,
      } );
    case "GET_ALL_VIDEOS":
      return Object.assign( {}, state, {
        list: action.data,
        all_videos: action.data.all_videos,
        loading_get_videos: false,
      } );
    case "LOADING_GET_VIDEOS":
      return Object.assign( {}, state, { loading_get_videos: true } );
    case "LOADING_EDIT_USER":
      return Object.assign( {}, state, { loading_edit_videos: true } );
    case "LOADING_GET_VIDEO":
      return Object.assign( {}, state, { loading_get_videos: true } );
    case "RESPONSE_DELETE_VIDEO ":
      list.splice( action.index, 1 );
      return Object.assign( {}, state, { list: list } );
    case "GET_SEARCH_VIDEOS":
      return Object.assign( {}, state, {
        list: action.data,
        all_videos: action.data,
        show_search_videos: true,
      } );
    case "UPLOAD_VIDEO":
      return Object.assign( {}, state, { loading_upload_videos: true } );
    case "VIDEO_CURRENT_DATA":
      return Object.assign( {}, state, { current: action.videoData } );
    default:
      return state;
  }
}
