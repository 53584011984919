import video_model from '../models/video'
import users_studies_model from './users_studies';

const study_model = {
    "study_id": '',
    "name": '',
    "users": [],
    "advertiser": '',
    "tags": '',
    "date_limit": '',
    "redirect_complete": 'https://transit.nicequest.com/transit/participation?tp=co_1&c=ok&ticket={{ticket}}',
    "redirect_reject": 'https://transit.nicequest.com/transit/participation?tp=fo_0&c=ok&ticket={{ticket}}',
    "redirect_full": 'https://transit.nicequest.com/transit/participation?tp=qf_1&c=ok&ticket={{ticket}}',
    "total_views": 0,
    "status": '',
    "can_edit": true,
    "language": 'pt-br',
    "videos": [
        video_model
    ],
    "painel_id": 1,
    "product_id": 1,
};

export default study_model;
