import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { loginFace } from "../../actions/userActions";
import { bindActionCreators } from "redux";
import Webcam from "react-webcam";
import { getStudy, eventVideo, getPlaylist } from '../../actions/watchActions';
import { translate } from "../../components/Translate";

const videoConstraints = {
  width: 450,
  height: 340,
  facingMode: "user"
};


export default class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      response_login: false,
      infoData: null,
      termos: true,
      redirect_loading: false
    };
    this.webcam = null;
    this._onUserMedia = this._onUserMedia.bind(this);
    this.changeTermos = this.changeTermos.bind(this);
  }

  setRef = webcam => {
    this.webcam = webcam;
  };

  async componentDidMount() {
    //document.body.classList.add("login-page");
    if (this.props.study_id) {
      this.study_id = this.props.study_id;
      let response = await getPlaylist(this.study_id);
      this.setState({
        infoData: response.data
      })
    }
  }

  capture = async () => {
    const imageSrc = this.webcam.getScreenshot();
    if (imageSrc != null) {
      this.setState({ loading: true });
      let response = await loginFace({ img: imageSrc, study_id: this.study_id });
      this.setState({
        response_login: response.data,
      });
      if (response.data.status) {
        localStorage.setItem('panelistToken', response.data.token);
        this.props.onLogin();
      } else {
        this.setState({ loading: false })
      }
    } else {
      window.setTimeout(() => this.capture(), 3000);
    }
  };


  openWebcam() {
    if (this.state.termos) {
      this.setState({ showWebcam: true, loading: false, response_login: false, redirect_loading: true });
    } else {
      alert("Você precisa aceitar os termos de uso para iniciar a pesquisa.")
    }
  }

  _onUserMedia() {
    window.setTimeout(() => this.capture(), 3000);
  }

  closeModal() {
    this.setState({ showWebcam: false, redirect_loading: false });
  }
  changeTermos(e) {
    this.setState({
      termos: e.target.checked
    });
  }

  cleanUrl(url) {
    this.setState({
      redirect_loading: true
    });
    let tags = url.match(/({{[a-z_0-9]+}})/gi);
    let query_string = window.location.search.replaceAll("%3C", "").replaceAll("%3E", "").replaceAll('(', '').replaceAll(')', '');
    let params = new URLSearchParams(query_string);

    tags.map((val) => {
      url = url.replace(val, params.get(val.match(/[a-z_0-9]+/)[0]));
    });

    const redirect_url = new URL(url)

    let searchParams = new URLSearchParams(redirect_url.search);
    let post = { "study_video_id": this.state.infoData[0].study_video_id };
    for (var pair of searchParams.entries()) {
      post[pair[0]] = pair[1];
    }
    eventVideo(post).then(() => {
      window.location.href = url;
    });
  }

  render() {
    const { response_login, infoData, termos, redirect_loading } = this.state;
    return (
      infoData == null ? <div>
        <p className="text-center py-4">
          <span className="fa fa-4x fa-refresh fa-spin"></span>
        </p>
      </div>
        :
        <div>
          <nav className="navbar fixed-top navbar-expand-lg" id="sectionsNav">
            <div className="container-fluid">
              <div className="navbar-translate mb-2 mt-2">
                <img src="https://emotions.brandful.com.br/assets/img/logo-novo.png" height="55" alt="Brandful" />
              </div>

              <div className="collapse navbar-collapse hidden-print">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a className="nav-link" target="_blank" href="https://emotions-ws.brandful.com.br/privacy">{translate('Termos de Uso', infoData[0]['language'])}</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>

          <ol className="breadcrumb py-4">
            <li className="breadcrumb-item font-weight-bold">{translate('Painelista', infoData[0]['language'])} | {translate('Bem Vindo', infoData[0]['language'])}</li>
          </ol>
          <div className="container-fluid" style={{ paddingBottom: '60px' }}>
            <h3 className="pt-4" style={{ overflowWrap: "break-word" }}><strong>{infoData[0]['study_name']}</strong></h3>

            <div dangerouslySetInnerHTML={{ __html: translate('regras_participacao', infoData[0]['language']) }}></div>

            <p className="font-weight-bold">{translate('Minhas Tarefas', infoData[0]['language'])}</p>
            <div className="card d-md-none">
              <img src={infoData[0]['thumbnail']} className="card-img-top" alt={infoData[0]['video_id']} />
              <div className="card-body">
                <dl className="row">
                  <dt className="col-sm-3">{translate('Tipo', infoData[0]['language'])}</dt>
                  <dd className="col-sm-9">{infoData[0]['type']}</dd>
                  <dt className="col-sm-3">{translate('Requerimento', infoData[0]['language'])}</dt>
                  <dd className="col-sm-9">{infoData[0]['requirement']}</dd>
                  <dt className="col-sm-3">{translate('Qtd. Vídeos', infoData[0]['language'])}</dt>
                  <dd className="col-sm-9">{infoData.lenght}</dd>
                </dl>
                {/* <label htmlFor="termos">
                       <span className="pr-2"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span> 
                      Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                  </label> */}
                {redirect_loading ? <div className="text-center"><span className="fa fa-4x fa-refresh fa-spin"></span></div> :
                  <div className="text-center">

                    <button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"} onClick={() => this.openWebcam()}>
                      <span className="fa fa-eye"></span> {translate('Participar', infoData[0]['language'])}
                    </button>
                    {infoData[0]['redirect_reject'] != null ?
                      <p><button onClick={() => { this.cleanUrl(infoData[0]['redirect_reject']) }} className="btn btn-round btn-block">{translate('Recusar Pontos', infoData[0]['language'])}</button></p>
                      : null}
                  </div>}
              </div>
            </div>
            <table className="table table-hover table-bordered d-none d-md-table">
              <thead>
                <tr>
                  <th colSpan="2">ID</th>
                  <th>{translate('Tipo', infoData[0]['language'])}</th>
                  <th>{translate('Requerimento', infoData[0]['language'])}</th>
                  <th>{translate('Qtd. Vídeos', infoData[0]['language'])}</th>
                  <th width="100"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{infoData[0]['study_id']}</td>
                  <td><img src={infoData[0]['thumbnail']} height="150px" /></td>
                  <td>{infoData[0]['type']}</td>
                  <td>{infoData[0]['requirement']}</td>
                  <td>{infoData.length}</td>
                  {/* <td width="150" className="text-center">
                      <label htmlFor="termos">
                        <span className="d-block"><input type="checkbox" id="termos" name="termos" value="1" onChange={this.changeTermos} /></span>
                        Li e aceiro os <a href="https://emotions-ws.brandful.com.br/privacy" target="_blank">termos de uso e política de privacidade</a> para participação nesta pesquisa.
                      </label>
                    </td> */}
                  <td>
                    {redirect_loading ? <div className="text-center"><span className="fa fa-refresh fa-spin"></span></div> :
                      <div className="text-center">
                        <button className={termos ? "btn btn-success btn-round btn-block" : "btn btn-success btn-round disabled btn-block"} onClick={() => this.openWebcam()}>
                          <span className="fa fa-eye"></span> {translate('Participar', infoData[0]['language'])}
                        </button>
                        {infoData[0]['redirect_reject'] != null ?
                          <p><button onClick={() => { this.cleanUrl(infoData[0]['redirect_reject']) }} className="btn btn-round btn-block">{translate('Recusar Pontos', infoData[0]['language'])}</button></p>
                          : null}
                      </div>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {
            this.state.showWebcam ? (
              <div>
                <div className="modal fade show" style={{ display: "block" }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{translate('Identificação Facial', infoData[0]['language'])}</h5>
                        <button
                          type="button"
                          className="close"
                          onClick={() => this.closeModal()}
                        >
                          <i className="material-icons">clear</i>
                        </button>
                      </div>
                      <div className="modal-body">
                        {response_login !== false ?
                          <div className={response_login.status === 1 ? "alert alert-success" : "alert alert-danger"}>
                            {response_login.msg}
                          </div>
                          : null}

                        {this.state.loading ? (
                          <p className="text-center">
                            <span className="fa fa-2x fa-refresh fa-spin"></span>
                          </p>
                        ) : (response_login === false ? <Webcam
                          audio={false}
                          height={340}
                          width={450}
                          mirrored={true}
                          screenshotFormat="image/jpeg"
                          screenshotQuality={1}
                          minScreenshotHeight={340}
                          minScreenshotWidth={450}
                          ref={this.setRef}
                          onUserMedia={this._onUserMedia}
                          videoConstraints={videoConstraints}
                        /> : null)}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="modal-backdrop fade show"
                  onClick={() => this.closeModal()}
                ></div>
              </div>
            ) : null
          }
        </div >
    );
  }
}
