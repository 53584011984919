import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux'
import { changeStudyData } from '../../../actions/studiesActions'

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { getUsersAtivos } from '../../../actions/userActions';

export class StudyAddUsers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      users: [],
      botaoOn: false
    }
    this.onChange = this.onChange.bind(this);
    this.addUser = this.addUser.bind(this)

  }


  filterContacts() {
    let users = [];

    let studyData = this.props.studyData;
    console.log(studyData);
    let contatcs = [];
    studyData.users.map(contact => {
      contatcs.push(contact.email);
    })
    getUsersAtivos().then(response => {
      response.data.map(data => {

        if (contatcs.indexOf(data.email) === -1) {
          users.push(data);
        }
      })
      this.setState({ users })
    })
  }

  addUser(userdata) {


    this.setState({ botaoOn: true });
    let data = this.props.studyData;
    let users = this.state.users;
    // console.log(data.users)
    // console.log(userdata.user_id)



    if (data.users.indexOf(userdata) === -1) {
      //    alert("aquki")

      users.map((user, key) => {
        if (user.user_id === userdata.user_id) {//antes == depois ===
          users.splice(key, 1);
        }
      })
      this._removeCampagn();
      data.users.push(userdata);
      this.props.changeStudyData(data);
      this.setState({ update: true });

    }

    this.setState({ botaoOn: false });
  }


  async onChange(e, index) {
    let data = await this.props.studyData
    data.users[index][e.target.name] = e.target.value;

    this.props.changeStudyData(data);

    console.log("Caiu aqui")
    this.setState({ update: true });
  }

  _novoContato = () => {
    this.setState({ showModal: true });
  }
  closeModal = () => {
    this.setState({ showModal: false });
  }

  addButton(cell, item, extra) {
    if (!this.botaoOn) {
      return <button className="btn btn-sm btn-success btn-round" type="button" onClick={() => { extra(item) }}>Adicionar</button>

    }
  }



  _removeContato = index => {
    let data = this.props.studyData
    this.state.users.push(data.users[index])
    data.users.splice(index, 1);
    this.props.changeStudyData(data);
    this.setState({ update: true });

  }

  _removeCampagn = index => {
    let data = this.props.studyData

    data.account_id = '';
    this.props.changeStudyData(data);
    this.setState({ update: true });
    //this.props.updateMyState()
  }




  render() {
    let { studyData } = this.props;
    let { users, showModal } = this.state;

    let data = this.props.studyData
    if (users.length == 0) {
      this.filterContacts()
    }


    return (
      <fieldset className="mt-2 mb-4">
        <h3 className='title m-0 h4'>Permissionamento:</h3>
        {studyData.users.length > 0 ?
          <table className="table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Sobrenome</th>
                <th>Email</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {studyData.users.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{item.name}</td>
                    <td>{item.lastname}</td>
                    <td>{item.email}</td>
                    <td><button type="button" className="btn btn-danger btn-sm btn-circle" onClick={() => this._removeContato(i)}><i className="fa fa-minus"></i></button></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          : null}
        <button type="button" className="btn btn-success btn-round" onClick={this._novoContato}><i className="fa fa-plus"></i> Adicionar</button>
        {showModal ?
          <div>
            <div className="modal fade show" style={{ display: "block" }}>
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Adicionar contato para essa pesquisa</h5>
                    <button type="button" className="close" onClick={() => this.closeModal()}>
                      <i className="material-icons">clear</i>
                    </button>
                  </div>
                  <div className="modal-body">
                    <BootstrapTable data={users} hover search options={{ noDataText: "Nenhum usuário encontrado" }} searchPlaceholder="Procurar..." pagination>
                      <TableHeaderColumn isKey dataField='user_id' width='50' dataAlign='center' dataSort={true}>ID</TableHeaderColumn>
                      <TableHeaderColumn dataField='name'>Nome</TableHeaderColumn>
                      <TableHeaderColumn dataField='lastname'>Sobrenome</TableHeaderColumn>
                      <TableHeaderColumn dataField='email'>Email</TableHeaderColumn>
                      <TableHeaderColumn dataField='phone'>Telefone</TableHeaderColumn>
                      <TableHeaderColumn formatExtraData={this.addUser} dataField='ID' width='100' dataAlign='center' dataFormat={this.addButton}></TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-backdrop fade show" onClick={() => this.closeModal()}></div>
          </div>
          : null}
      </fieldset>
    )
  }
}
const mapStateToProps = store => ({
  studyData: store.studies.current
})

const mapDispatchToProps = dispatch =>
  bindActionCreators({ changeStudyData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StudyAddUsers)
