import api from '../api';

export function getGroups() {
  return api.get('groups');
}

export function deleteGroup(group_id) {
  return api.delete('groups/' + group_id);
}

export function getGroup(group_id) {
  return api.get('groups/' + group_id);
}

export function saveGroup(data, group_id) {
  if (group_id) {
    return api.patch('groups/' + group_id, data);
  } else {
    return api.post('groups', data);
  }
}

export function uploadLogo(data) {
  let postData = new FormData();
  postData.append('file', data);
  postData.append('path', 'grupo');
  return api.post('uploadFile', postData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function removerLogo(url) {
  return api.delete('uploadFile/' + btoa(url));
}