import api from '../api';

export function getStudiesStatus(st) {
    return api.get('studies?status=' + st);
}

export function getStudiesAvailable() {
    return api.get('studies?cancelled_at=null');
}

export function changeStudyData(data) {
    return dispatch => {
        dispatch({
            type: "STUDY_CURRENT_DATA",
            studyData: data
        });
    }
}

export function toogleStudy(study_id) {
    return api.put('studies/' + study_id + '/toggle');
}

export function inviteUsers(study_id) {
    return api.put('studies/' + study_id + '/invite');
}

export function getStudies() {
    return async dispatch => {
        dispatch({
            type: 'LOADING_GET_CAMPAIGNS',
        });

        return api.get(`studies/`).then(res => {
            dispatch({
                type: "GET_ALL_STUDIES",
                data: res.data
            });
        });
    };
}

export function getStudiesSearch() {
    return async dispatch => {
        // dispatch({
        //     type: 'LOADING_LIST_STUDIES',
        // });

        return api.get('studies?status=available').then(res => {
            dispatch({
                "type": "GET_ALL_STUDIES",
                "data": res.data
            })
        });

    }
}

export function deleteStudy(index, study_id) {
    return async dispatch => {
        dispatch({
            "type": 'DELETE_STUDY',
            "index": index
        });
        return api.delete('studies/' + study_id)
    }
}

export function resetStudyData() {
    return async dispatch => {
        dispatch({
            "type": "RESET_CURRENT"
        })
    }
}

export function addStudy(data) {
    return dispatch => {
        dispatch({
            type: "LOADING_SAVE_STUDY",
            loading_save: true
        });
        api.post("studies", data).then(response => {
            if (response.data.status === 1) {
                dispatch({
                    type: "ADD_STUDY",
                    data: {
                        "ID": response.data.study_id,
                        "name": data.name
                    }
                });
                //window.location.href = '/studies/info/'+response.data.study_id;
                window.location.href = '/studies/';
            } else {
                alert(response.data.msg);
            }
            dispatch({
                type: "LOADING_SAVE_STUDY",
                loading_save: false
            });
        });
    }
}

export function getStudy(study_id, params = false) {

    return async dispatch => {
        return api.get('studies/' + study_id + (params ? '?' + params : ''),).then(res => {

            dispatch({
                "type": "GET_STUDY",
                "data": res.data
            })
        });
    }

}

export function updateStudy(data, study_id) {
    return dispatch => {
        dispatch({
            type: "LOADING_SAVE_STUDY",
            loading_save: true
        });
        api.patch("studies/" + study_id, data).then(response => {
            alert(response.data.msg);
            dispatch({
                type: "LOADING_SAVE_STUDY",
                loading_save: false
            });
        });
    }
}

export function getPaineis() {
    return dispatch => {
        return api.get('painel/').then(response => {
            dispatch({
                type: "RESPONSE_PAINEIS",
                response: response.data
            });

        });
    }
}


export function getProducts() {
    return dispatch => {
        return api.get('products/').then(response => {
            dispatch({
                type: "RESPONSE_PRODUCTS",
                response: response.data
            });

        });
    }
}
